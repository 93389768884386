/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useState, createContext } from "react";
import { useQuery } from "react-query";
import { initialQueryResponse } from "../../../../_clientPage/assets/helper/model";
import { getCategories, getImages, GET_CATEGORIES_URL } from "./_requests";

const QueryCategoryResponseContext = createContext(initialQueryResponse);
const QueryCategoryResponseProvider = ({ children }) => {
  const [query, setQuery] = useState("/categories");
  const [categoryData, setCategoryData] = useState(undefined);
  const [categoryDataAndImage, setCategoryDataAndImage] = useState(undefined);
  const { isFetching: isLoading,refetch, data: response } = useQuery(
    `${GET_CATEGORIES_URL}`,
    () => {
      return getCategories();
    },
    {
      onSuccess: (data) => {
        setCategoryData(data);
      },
      cacheTime: 0,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );
console.log(categoryData)

// **** no need images ****
  // const {
  //   isFetching: isLoading2,
  //   refetch,
  //   data: response2,
  // } = useQuery(
  //   ["categoriesRenderingFilesAndImages", { responseCategories: categoryData }],
  //   getImages,
  //   {
  //     onSuccess: (data) => {
  //       const data2 = [...data];
  //       setCategoryDataAndImage(data2);
  //     },
  //     enabled: !!categoryData,
  //     cacheTime: 0,
  //     keepPreviousData: true,
  //     refetchOnWindowFocus: false,
  //   }
  // );

  return (
  
        <QueryCategoryResponseContext.Provider
          value={{ isLoading: isLoading, refetch, categoryData, query }}
        >
          {children}
        </QueryCategoryResponseContext.Provider>
  );
};

const useQueryResponse = () => useContext(QueryCategoryResponseContext);

const useQueryResponseCategoryData = () => {
  const { categoryData } = useQueryResponse();
  if (!categoryData) {
    return [];
  }

  return categoryData|| [];
};

const useQueryResponseCategoryLoading = () => {
  const { isLoading } = useQueryResponse();
  return isLoading;
};
export {
  QueryCategoryResponseProvider,
  useQueryResponse,
  useQueryResponseCategoryData,
  useQueryResponseCategoryLoading,
};
