import { Link } from 'react-router-dom';
import {useQueryResponseCartData} from '../../../../../app/providers/privateProviders/cart/QueryResponseProviderCart'


const Cart = ({classes,classesWrapper}) => {

    const cart = useQueryResponseCartData();

    return (
        <div className={classesWrapper}>
            <Link className="cart-menu" to="/cart">
                <i className={classes}> {(cart.length > 0) && <span className="badge">{(cart.reduce((a,v) =>  a = a + v.quantity , 0 ))}</span>}</i>            
            </Link>
        </div>
    )
}
export default Cart;