import { QueryCountryResponseProvider } from "./Country/core/QueryResponseProviderCountry";
import { QueryLanguageResponseProvider } from "./Language/core/QueryResponseProviderLang";
import { NotificationProvider } from "./Notification/NotificationProvider";

const ContextsWrapper = ({ children }) => {
  return (
    <QueryCountryResponseProvider>
      <QueryLanguageResponseProvider>
        <NotificationProvider>{children}</NotificationProvider>
      </QueryLanguageResponseProvider>
    </QueryCountryResponseProvider>
  );
};

export { ContextsWrapper };
