import { lazy } from "react";
import { Route, Routes, Navigate, Outlet } from "react-router-dom";
import React from "react";
import { ErrorsPage } from "../modules/errors/ErrorsPage";
import SidebarLayout from "../../_clientPage/layout/partials/sidebar/SidebarLayout";
import { SuspensedView } from "../../_clientPage/layout/core/SuspensedViews";
import { QueryAddressesResponseProvider } from "../modules/shipping/core/QueryResponseProviderAddresses";
const PrivateRoutes = () => {
  const BestSellingItems = lazy(() =>
    import("../modules/best-selling-item/Overview")
  );
  const SelectBrandPage = lazy(() => import("../modules/brand/Overview"));
  const SelectTypePage = lazy(() => import("../modules/product-type/page/SelectType"));
  const SelectProduct = lazy(() =>
    import("../modules/select-product/page/SelectProduct")
  );
  const Product = lazy(() => import("../modules/product/page/Overview"));
  const ConfirmedOrders = lazy(() =>
    import("../modules/orders/confirmed-orders/page/confirmed-orders")
  );

  const PreviousOrders = lazy(() =>
  import("../modules/orders/previous-orders/page/previous-orders")
);

  const PendingOrders = lazy(() =>
  import("../modules/orders/pending-orders/page/pending-orders")
);

const ProblematicOrders = lazy(() =>
import("../modules/orders/problematic-orders/page/problematic-orders")
);
  // const InstallationAddresses = lazy(() =>
  //   import("../modules/warehouse/submodules/installation-addresses/table/installation-addresses")
  // );

  // const YourWarehouses = lazy(() =>
  //   import("../modules/warehouse/your-warehouse/your-warehouse")
  // );

  const AddressesPage = lazy(() =>
    import("../modules/addresses/routing/AddressesPage")
  );

  const SuccesPay = lazy(() =>
  import("../modules/success-pay/succes-pay")
);

  const OrderSummary = lazy(() =>
    import("../modules/order-summary/page/Overview")
  );

  const Shipping = lazy(() =>
    import("../modules/shipping/shipping")
  );

  const CartPage = lazy(() =>
    import("../modules/cart/cart")
  );
  return (
    <Routes>
      <Route>
        <Route
          path="auth/*"
          element={<Navigate to={"/best-selling-items"} />}
        />
        <Route
          element={
            <SuspensedView>
              <SidebarLayout />
            </SuspensedView>
          }
        >

  
            <Route path="/product" element={<SuspensedView><Product /></SuspensedView>} />
            <Route path="/product/:id" element={<SuspensedView><Product /></SuspensedView>} />
            <Route path="/select-product" element={<SuspensedView><SelectProduct /></SuspensedView>} />
            <Route path="/best-selling-items" element={
              <SuspensedView>
                <BestSellingItems />
              </SuspensedView>} />

          {/*Product Provider END*/}
            <Route path="/select-brand" element={
              <SuspensedView><SelectBrandPage /></SuspensedView>} />
          {/*Brand Provider END*/}

          {/*Type Provider START*/}
            <Route path="/select-type" element={<SuspensedView><SelectTypePage /></SuspensedView>} />
          {/*Type Provider END*/}



          <Route path="/confirmed-orders" element={<SuspensedView><ConfirmedOrders /></SuspensedView>} />
          <Route path="/cart" element={<SuspensedView><CartPage /></SuspensedView>} />
          <Route path="/problematic-orders" element={<SuspensedView><ProblematicOrders /></SuspensedView>} />
          <Route path="/pending-orders" element={<SuspensedView><PendingOrders /></SuspensedView>}/>
          <Route path="/previous-orders" element={<SuspensedView><PreviousOrders /></SuspensedView>}/>

          <Route path="/success-payment" element={<SuspensedView><SuccesPay /></SuspensedView>} />

          <Route path="/order-summary/:id" element={<SuspensedView><OrderSummary /></SuspensedView>} />

          <Route
            element={
              <QueryAddressesResponseProvider>
                <Outlet />
              </QueryAddressesResponseProvider>
            }
          >
            <Route path="/shipping" element={<SuspensedView><Shipping /></SuspensedView>} />
          </Route>
          <Route
            path="/addresses/*"
            element={
              <SuspensedView>
                <AddressesPage />
              </SuspensedView>
            }
          />
          {/* <Route
            path="/installation-addresses"
            element={<SuspensedView><InstallationAddresses /></SuspensedView>}
          />
          <Route path="/your-warehouses" element={<SuspensedView><YourWarehouses /></SuspensedView>} />*/}

          {/* Page Not Found */}
          <Route path="*" element={<Navigate to="/error/404" />} />
        </Route>

      </Route>



    </Routes>
  );
};



export { PrivateRoutes };
