import { Suspense } from "react";
import TopBarProgress from "react-topbar-progress-indicator";

export function SuspensedView({ children }){
    // const baseColor = getCSSVariableValue("--bs-primary");
  
    TopBarProgress.config({
      barColors: {
        0: "#E4072F",
      },
      barThickness: 2,
      shadowBlur: 5,
    });
    return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>;
  };