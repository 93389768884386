import React, { useState } from 'react';
import {
  ProSidebar,
  Menu,
  MenuItem,
  SubMenu,
  SidebarHeader,
  SidebarFooter,
  SidebarContent,
} from 'react-pro-sidebar';
import { Link } from 'react-router-dom';
import { AiOutlinePlus } from 'react-icons/ai';
import Warehouse from '../../../../assets/reactSVGComponents/icons/warehouse';
import { useLocation } from 'react-router-dom';
import {
  useQueryResponseCategoryData,
  useQueryResponseCategoryLoading,
} from "../../../../../app/providers/privateProviders/product-category/QueryResponseProviderCategory";
import { TailLoading } from '../../components/loading/TailSpinner'
import { useAuth } from '../../../../../app/modules/auth'
import { MdAssignmentInd } from "react-icons/md";
import Cart from '../../../../../_clientPage/assets/reactSVGComponents/icons/cart'
import AccountModalWrapper from '../modals/Account/AccountModalWrapper'
import NewAddressModal from '../modals/AddWarehouse/NewAddressModal';
import ConnectedUsersModal from '../modals/JoinedUsers/ConnectedUsersModal';
const Aside = ({ collapsed, rtl, toggled, handleToggleSidebar }) => {

  const location = useLocation();
  const categories = useQueryResponseCategoryData();
  const loading = useQueryResponseCategoryLoading()
  const { logout } = useAuth()

  //Modals values START
  const [isShowAccountModal, setIsShowAccountModal] = useState(false);
  const [isShowNewAddressModal, setIsShowNewAddressModal] = useState(false);
  const [isShowConnectedUsersModal, setIsShowConnectedUsersModal] = useState(false);
  //Modals values END


  //Handle Modals START
  const handleAccountModal = () => {
    setIsShowAccountModal(!isShowAccountModal)
  }

  const handleCancelAccountModal = () => {
    setIsShowAccountModal(false)
  }


  const handleNewAddressModal = () => {
    setIsShowNewAddressModal(!isShowNewAddressModal)
  }

  const handleCancelNewAddressModal = () => {
    setIsShowNewAddressModal(false)
  }

  const handleConnectedUsers = () => {
    setIsShowConnectedUsersModal(!isShowConnectedUsersModal)
  }

  const handleCancelConnectedUsersModal = () => {
    setIsShowConnectedUsersModal(false)
  }
  //Handle Modals END


  return (
    <div className="sidebar-main-wrapper">

      <ProSidebar
        rtl={rtl}
        collapsed={collapsed}
        toggled={toggled}
        breakPoint="md"
        onToggle={handleToggleSidebar}
        className='aside-hoverable'
      >
        <SidebarHeader>
          <div className='sideber-header'>
            <button type="button" id="navbar__control-button" className="logo-container" />
          </div>
        </SidebarHeader>

        <SidebarContent>
          <Menu iconShape="circle">
            <MenuItem className='title-wrapper'>
              <span className="title section-title--medium  red-text _bold">Warehouse</span>
            </MenuItem>
          </Menu>
          <Menu iconShape="circle" className='main-menu'>
            <SubMenu
              className='submenu'
              title={"BROWSE ITEMS"}
              icon={<Cart />}
              defaultOpen
            >
              {
                !loading && categories.map((item, index) => {
                  return (<MenuItem key={`${item.name + "-index-" + index}`} className={`${location.search.includes(encodeURI(item.name)) ? " active " : ""}`}><Link className={`list-item-custom`} to={`select-brand?categoryId=${item.id}`}>{item.name}</Link></MenuItem>)
                })
              }
              {
                loading && <TailLoading />
              }
              {/* <MenuItem className={`${location.pathname === "/select-brand/solar" ? " active " : ""}`}><Link className={`list-item-custom`} to="/select-brand/solar">SOLAR</Link></MenuItem>
            <MenuItem className={`${location.pathname === "/select-brand/inverter" ? " active " : ""}`}><Link className="list-item-custom" to="/select-brand/inverter">INVERTER</Link></MenuItem> */}
              <MenuItem className={`${location.pathname === "/best-selling-items" ? " active " : ""}`}><Link className="list-item-custom" to="/best-selling-items">Best Selling Items</Link></MenuItem>

            </SubMenu>
            <SubMenu
              className='submenu second-sub'
              // prefix={<span className="badge gray">3</span>}
              title={"MANAGE ORDERS"}
              icon={<Warehouse />}
            >
              <MenuItem className={`${location.pathname === "/pending-orders" ? " active " : ""}`}><Link className="list-item-custom" to="/pending-orders">Pending Orders</Link></MenuItem>
              <MenuItem className={`${location.pathname === "/problematic-orders" ? " active " : ""}`}><Link className={`list-item-custom`} to="/problematic-orders">Problematic Orders</Link></MenuItem>
              <MenuItem className={`${location.pathname === "/confirmed-orders" ? " active " : ""}`}><Link className={`list-item-custom`} to="/confirmed-orders">Confirmed Orders</Link></MenuItem>
              <MenuItem className={`${location.pathname === "/previous-orders" ? " active " : ""}`}><Link className="list-item-custom" to="/previous-orders">Previous Orders</Link></MenuItem>
            </SubMenu>
            <SubMenu
              className='submenu second-sub warehouse-menu'
              // prefix={<span className="badge gray">3</span>}
              title={"WAREHOUSES"}
              icon={<AiOutlinePlus />}
            >
              <MenuItem className={`${location.pathname === "/your-warehouses" ? " active " : ""}`}><Link className="list-item-custom" to="/addresses/your-warehouses">Your Warehouses</Link></MenuItem>
              <MenuItem className={`${location.pathname === "/installation-addresses" ? " active " : ""}`}><Link className="list-item-custom" to="/addresses/installation-addresses">Installation Addresses</Link></MenuItem>

              <MenuItem
                // icon={<AiOutlinePlus />}
                onClick={handleNewAddressModal}

              >
                <div className={`list-item-custom`}>
                  Add New Address
                </div>
              </MenuItem>
              <NewAddressModal isShow={isShowNewAddressModal} onCancel={handleCancelNewAddressModal} />

            </SubMenu>

            <SubMenu
              className='submenu second-sub'
              // prefix={<span className="badge gray">3</span>}
              title={"SYSTEM"}
              icon={<MdAssignmentInd />}
            >
              <MenuItem
                onClick={handleAccountModal}
              >
                <div className={`list-item-custom`}>
                  Your account
                </div>
              </MenuItem>
              <AccountModalWrapper isShow={isShowAccountModal} onCancel={handleCancelAccountModal} />

              <MenuItem
                onClick={handleConnectedUsers}
              >
                <div className={`list-item-custom`}>
                  Joined users
                </div>
              </MenuItem>
              <ConnectedUsersModal isShow={isShowConnectedUsersModal} onCancel={handleCancelConnectedUsersModal} />
              <MenuItem><button style={{ background: 'transparent' }} onClick={logout} className="list-item-custom" to="/pending-orders">Log out</button></MenuItem>

            </SubMenu>
          </Menu>

          <Menu iconShape="circle" className='main-menu'>


          </Menu>
          {/* <Menu iconShape="circle">
          <Modal type="add-warehouse" />
        </Menu> */}
        </SidebarContent>

        <SidebarFooter style={{ textAlign: 'center' }}>
          <div
            className="sidebar-btn-wrapper"
            style={{
              padding: '20px 24px',
            }}
          >
            <div className="bot-wrapper">
              {/* <Modal type="account" /> */}
              <div className='bot-image'></div>
            </div>
          </div>
        </SidebarFooter>
      </ProSidebar>
    </div>

  );
};

export default Aside;