import axios from 'axios'




const APPZ4_BACKEND_URL = process.env.REACT_APP_APPZ4_BACKEND_URL
export const COUNTRY_URL = `${APPZ4_BACKEND_URL}/country`



export async function getCountries () {
  return axios
    .get(`${COUNTRY_URL}`)
    .then((d) => {   
return d.data
})
}
