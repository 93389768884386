import axios from 'axios'

const APPZ4_BACKEND_URL = process.env.REACT_APP_APPZ4_BACKEND_URL
export const GET_AFFILIATE_GUEST_URL = `${APPZ4_BACKEND_URL}/user`

const getAffiliateGuests = (id) => {
  return axios
    .get(`${GET_AFFILIATE_GUEST_URL}/${id}/guests`)
    .then((d) => d.data.data?.guests)
}


export { getAffiliateGuests }
