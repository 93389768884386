/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useState, createContext } from "react";
import { useQuery } from "react-query";
import { initialQueryResponse } from "../../../../../../assets/helper/model";
import { getAffiliateGuests, GET_AFFILIATE_GUEST_URL } from "./_requests";
import { useAuth } from "../../../../../../../app/modules/auth";

const QueryAffiliateGuestsResponseContext = createContext(initialQueryResponse);
const QueryAffiliateGuestsResponseProvider = ({ children }) => {
  const [query, setQuery] = useState("/affiliate-guests");
  const { currentUser } = useAuth();

  const { isFetching: isLoading, refetch,data: response } = useQuery(
    `${GET_AFFILIATE_GUEST_URL}`,
    () => {
      return getAffiliateGuests(currentUser.data.user.id);
    },
    {
      cacheTime: 0,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );


  return (
  
        <QueryAffiliateGuestsResponseContext.Provider
          value={{ isLoading: isLoading, refetch, response, query }}
        >
          {children}
        </QueryAffiliateGuestsResponseContext.Provider>
  );
};

const useQueryResponse = () => useContext(QueryAffiliateGuestsResponseContext);

const useQueryResponseAffiliateGuestsData = () => {
  const { response } = useQueryResponse();
  if (!response) {
    return [];
  }

  return response || [];
};

const useQueryResponseAffiliateGuestsLoading = () => {
  const { isLoading } = useQueryResponse();
  return isLoading;
};
export {
  QueryAffiliateGuestsResponseProvider,
  useQueryResponse,
  useQueryResponseAffiliateGuestsData,
  useQueryResponseAffiliateGuestsLoading,
};
