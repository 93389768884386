/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useState, createContext } from "react";
import { useQuery } from "react-query";
import { initialQueryResponse } from "../../../../_clientPage/assets/helper/model";
import { getAddressById } from "../requests";
import { useAuth } from "../../../modules/auth";

const QueryAddressResponseContext = createContext(initialQueryResponse);
const QueryAddressesResponseProvider = ({ children }) => {
  const [query, setQuery] = useState("/id/addresses");
  const { currentUser } = useAuth();
  const { isFetching: isLoading, refetch,data: response } = useQuery(query,
    () => {
      return getAddressById(currentUser.data.user.id);
    },
    {
      cacheTime: 0,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  return (
  
        <QueryAddressResponseContext.Provider
          value={{ isLoading: isLoading, refetch, response, query }}
        >
          {children}
        </QueryAddressResponseContext.Provider>
  );
};

const useQueryResponse = () => useContext(QueryAddressResponseContext);

const useQueryResponseAddressData = () => {
  const { response } = useQueryResponse();
  if (!response) {
    return [];
  }

  return response || [];
};

const useQueryResponseAddressLoading = () => {
  const { isLoading } = useQueryResponse();
  return isLoading;
};
export {
  QueryAddressesResponseProvider,
  useQueryResponse,
  useQueryResponseAddressData,
  useQueryResponseAddressLoading,
};
