import TooltipIcon from "../../../../../../_clientPage/assets/images/graphs/tooltip.svg"
import { useMemo, useState } from "react";
import CustomSelect from "../../../components/customSelect/select";
import { useAuth } from "../../../../../../app/modules/auth/core/Auth";
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { getUser, updateCustomer } from '../requests'
import { useQueryResponseCountryData } from '../../../../../../app/providers/globalProviders/Country/core/QueryResponseProviderCountry'
import clsx from "clsx";
import { forgottPassword } from "../../../../../../app/modules/auth/core/_requests";
import { errorHandle } from "../../../../../assets/helper/error-handler/errorHandler";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FiClipboard } from "react-icons/fi";
import { AUTH_LOCAL_STORAGE_KEY } from "../../../../../../app/modules/auth/core/_constants";

const customerDetailsSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    country_id: Yup.string().required('Country is required'),
    email: Yup.string().required('Email is required'),
})
const Account = ({ onCancel }) => {
    const { currentUser, setCurrentUser } = useAuth()
    const { user } = currentUser.data

    const value = useMemo(() => window.location.origin + `/auth/registration/${user?.affiliate_code?.code ? user?.affiliate_code?.code : "placeholder"}?name=${user.name}`, [])
    const [copied, setCopied] = useState(false)

    const onCopyText = () => {
        setCopied(true);
        setTimeout(() => {
            setCopied(false);
        }, 2000);
    };

    const [success, setSuccess] = useState(false)

    const [loading, setLoading] = useState(false)
    const [hasErrors, setHasErrors] = useState(undefined);
    const { logout } = useAuth()


    const countriesData = useQueryResponseCountryData();
    const sendNewPassword = () => {
        setLoading(true);
        setHasErrors(undefined);
        setTimeout(() => {
            forgottPassword(user.email, window.location.origin + "/auth/forgotten-password/")
                .then(({ data: { result } }) => {
                    setHasErrors(false);
                    setLoading(false);
                })
                .catch(() => {
                    setHasErrors(true);
                    setLoading(false);
                });
        }, 1000);
        setTimeout(() => {
            if (hasErrors) return
            logout()
        }, 2500);
    }
    const formik = useFormik({
        initialValues: user,
        validationSchema: customerDetailsSchema,
        onSubmit: async (values, { setStatus, setSubmitting, resetForm }) => {
            setSubmitting(true)
            try {
                await updateCustomer(
                    values
                )
                setSuccess(true)
                const neUserData = await getUser(user.id)
                setCurrentUser(neUserData.data)

                const data = JSON.parse(localStorage.getItem(AUTH_LOCAL_STORAGE_KEY))
                const newObj = { data: { token: data.data.token, user: neUserData.data.data.user } }
                localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, JSON.stringify(newObj))
                setTimeout(() => {
                    setSuccess(false)
                    onCancel()

                }, 1000)

            } catch (error) {
                setStatus('The details is incorrect')
                const errorText = errorHandle(error)
                setStatus(errorText)
                setTimeout(() => {
                    setStatus("")
                }, 2500)
            } finally {
                setSubmitting(false)
            }
        },
    })

    return (
        <div className="form_ver-red-wrapper">
            <div className="confirmed-bot__wrapper __modal-bot__account">
                <div className="bot-account"></div>
            </div>
            <form onSubmit={formik.handleSubmit} noValidate className="cutted-corner auths-form signup" action="#">
                <h3 className="section-title--big">My account</h3>
                <div className="gradient-border normal-input auth-input input-80">
                    <input
                        type='text'
                        className='form-control form-control-lg form-control-solid mb-lg-0'
                        placeholder='Name'
                        {...formik.getFieldProps('name')}
                    />
                </div>
                {formik.touched.name && formik.errors.name && (
                    <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.name}</div>
                    </div>
                )}
                <div className="gradient-border auth-input input-50 custom-select">

                    <CustomSelect
                        defaultText={(countriesData.find(item => item.id === user.country_id)).name}
                        optionsList={countriesData}
                        formik={formik}
                        fieldName={"country_id"}
                        searchable={true}
                    ></CustomSelect>                </div>
                {formik.touched.country && formik.errors.country && (
                    <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                            <span role="alert">
                                {formik.errors.country}{" "}
                            </span>
                        </div>
                    </div>
                )}

                <div className="gradient-border auth-input input-50">
                    <input
                        type="email"
                        autoComplete="off"
                        {...formik.getFieldProps("email")}
                        className={clsx(
                            "form-control form-control-lg form-control-solid",
                            {
                                "is-invalid":
                                    formik.touched.email && formik.errors.email,
                            },
                            {
                                "is-valid":
                                    formik.touched.email &&
                                    !formik.errors.email,
                            }
                        )}
                        placeholder="EMAIL"
                    />
                </div>
                {formik.touched.email && formik.errors.email && (
                    <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                            <span role="alert">{formik.errors.email}</span>
                        </div>
                    </div>
                )}
                <div className="tooltip-icon-wrapper auth-input">
                    <div className="gradient-border input-50 custom-tooltip">
                        <input disabled type="text"  {...formik.getFieldProps('vat_number')} placeholder="VAT NR." className="form-control" />
                        <img className="tooltip-icon" src={TooltipIcon}></img>
                    </div>
                </div>

                <div className="tooltip-icon-wrapper auth-input">
                    <div className="gradient-border input-80">
                        <input disabled type="text" placeholder={value} className="form-control" />
                    </div>
                    <CopyToClipboard text={value} onCopy={onCopyText} className={"copy-button"}>
                        <div className="copy-area">
                            <button type="button">Copy registration link <FiClipboard /></button>
                        </div>
                    </CopyToClipboard>

                </div>
                {copied ? <span style={{ color: 'white' }}>Copied.</span> : null}


                <div className="bottom-side mt-3">
                    <button type="button" className="description-link" onClick={sendNewPassword}> Change password</button>
                    {hasErrors === true && (
                        <div className="mb-lg-15 alert alert-danger">
                            <div className="alert-text font-weight-bold">
                                Sorry, looks like there are some errors detected, please
                                try again.
                            </div>
                        </div>
                    )}

                    {hasErrors === false && (
                        <div className="mb-10 bg-light-success p-8 rounded">
                            <div className="text-success">
                                Sent password reset. Please check your email
                            </div>
                        </div>
                    )}
                    {formik.status && (
                        <div className='alert alert-danger mt-6'>
                            <div className='alert-text font-weight-bold'>{formik.status}</div>
                        </div>
                    )}
                    {success && (
                        <div className='mb-10 bg-light-success p-4 rounded mt-6'>
                            <div className='text-success'>Changes were successfully saved, please login again!</div>
                        </div>
                    )}
                    <div className="button-wrapper">
                        <div className="button-container auth-input">
                            <button
                                type="submit"
                                id="kt_sign_in_submit"
                                className="global-button"
                                disabled={formik.isSubmitting || !formik.isValid}
                            >
                                {!loading && (
                                    <span className="indicator-label">Save</span>
                                )}
                                {loading && (
                                    <span
                                        className="indicator-progress"
                                        style={{ display: "block" }}
                                    >
                                        Please wait...
                                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                    </span>
                                )}
                            </button>
                        </div>

                        <div className="button-container auth-input">
                            <button type="button" className="global-button grey-background" onClick={onCancel}>Close</button>
                        </div>
                    </div>
                </div>

            </form>

        </div>
    )
}

export default Account;