import axios from 'axios'


const APPZ4_BACKEND_URL = process.env.REACT_APP_APPZ4_BACKEND_URL
export const GET_PRODUCTS_URL = `${APPZ4_BACKEND_URL}/product`
const GET_IMAGES_URL = `${APPZ4_BACKEND_URL}/file/`
const getFile = (response) => {
  let resultImage = btoa(
    new Uint8Array(response.data)
      .reduce((data, byte) => data + String.fromCharCode(byte), '')
  );

  return `data:${response.headers['content-type'].toLowerCase()};base64,${resultImage}`;
}
const getProducts = () => {
  return axios
    .get(`${GET_PRODUCTS_URL}`)
    .then((d) => d.data.data.products)
}

const getImages =  async ({ queryKey }) => {
  const [, { responseProducts }] = queryKey;

  const productsArray = [...responseProducts];
  const arrayList = []
  for (let i = 0; i < productsArray.length; i++) {
   
    const newProdPromise = await productsArray[i].images.map(async (image, index2) => {
      // for (let index = 0; index < arrayList[i].images.length; index++) {

      image.img_src = "";
      let imgPath = image.path

      try {
        const response = await axios.get(`${GET_IMAGES_URL}${btoa(imgPath)}`, { responseType: 'arraybuffer' })

        image.img_src = getFile(response)

      } catch (error) {

        console.log("image er: ", error)
      }
      return image
    })

    const newProd = await axios.all(newProdPromise)
    .then((resp) => { 
      productsArray[i].images = resp
    arrayList.push(resp)})
  }

  return productsArray
}

const getDocuments =  async ({ queryKey }) => {

  const [, { responseProducts }] = queryKey;

  const productsArray = [...responseProducts];
  const arrayList = []
  for (let i = 0; i < productsArray.length; i++) {
   
    const newProdPromise = await productsArray[i].documents.map(async (document, index2) => {
      // for (let index = 0; index < arrayList[i].images.length; index++) {

      document.document_src = "";
      let documentPath = document.path

      try {
        const response = await axios.get(`${GET_IMAGES_URL}${btoa(documentPath)}`, { responseType: 'arraybuffer' })

        document.document_src = getFile(response)

      } catch (error) {

        console.log("error Documeeeeeeeent: ", error)
      }
      return document
    })

    const newProd = await axios.all(newProdPromise)
    .then((resp) => { 
      productsArray[i].documents = resp
      console.log('prodArray: ',productsArray[i])
    arrayList.push(resp)})
  }

  return productsArray
}


export { getProducts, getImages,getDocuments }
