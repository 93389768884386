import axios from 'axios'



const APPZ4_BACKEND_URL = process.env.REACT_APP_APPZ4_BACKEND_URL

export const LANGUAGE_URL = `${APPZ4_BACKEND_URL}/language`


export function getLanguages () {
  return axios
    .get(`${APPZ4_BACKEND_URL}/language`)
    .then((d) => d.data)
}
