import axios from 'axios'

const APPZ4_BACKEND_URL = process.env.REACT_APP_APPZ4_BACKEND_URL
export const REGISTER_URL = `${APPZ4_BACKEND_URL}/address`

export function addressRegister(
  name,
  phone,
  zip,
  country_id,
  city,
  street,
  apartment,
  warehouse,
  isDefault
) {
  return axios.post(REGISTER_URL, {
    name: name,
    phone: phone,
    zip: zip,
    country_id: country_id,
    city: city,
    street: street,
    apartment: apartment,
    warehouse: warehouse,
    default: isDefault
  })
}

const getAddressById = (id) => {
  return axios
      .get(`${APPZ4_BACKEND_URL}/user/${id}/addresses`)
      .then((d) => d.data.data?.addresses)
}

export {getAddressById}