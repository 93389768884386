/* eslint-disable react-hooks/exhaustive-deps */
import {FC, useContext, useState, useEffect, useMemo,createContext} from 'react'
import {useQuery} from 'react-query'
import {
  initialQueryResponse,
} from '../../../../../_clientPage/assets/helper/model'
import {getLanguages,LANGUAGE_URL} from './requests'

const QueryLanguageResponseContext = createContext(initialQueryResponse)
const QueryLanguageResponseProvider= ({children}) => {
 
  const [query, setQuery] = useState(LANGUAGE_URL)

  const {
    isFetching,
    refetch,
    data: response,
  } = useQuery(
    `${query}`,
    () => {
      return getLanguages()
    },
    {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
  )



  return (
    <QueryLanguageResponseContext.Provider value={{isLoading: isFetching, refetch, response,query}}>
      {children}
    </QueryLanguageResponseContext.Provider>
  )
}

const useQueryResponse = () => useContext(QueryLanguageResponseContext)

const useQueryResponseLanguageData = () => {
  const {response} = useQueryResponse()
  if (!response) {
    return []
  }

  return response.data.languages || []
}


const useQueryResponseLoading = () => {
  const {isLoading} = useQueryResponse()
  return isLoading
}

export {
  QueryLanguageResponseProvider,
  useQueryResponse,
  useQueryResponseLanguageData,
  useQueryResponseLoading,
}
