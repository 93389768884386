/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useState, createContext } from "react";
import { useQuery } from "react-query";
import { initialQueryResponse } from "../../../../_clientPage/assets/helper/model";
import { getProducts, getImages,getDocuments, GET_PRODUCTS_URL } from "./_requests";

const QueryProductResponseContext = createContext(initialQueryResponse);
const QueryProductResponseProvider = ({ children }) => {
  const [query, setQuery] = useState("/products");
  const [productData, setProductData] = useState(undefined);
  const [productDataAndImage, setProductDataAndImage] = useState(undefined);
  const { isFetching: isLoading, refetch,data: response } = useQuery(
    `${GET_PRODUCTS_URL}`,
    () => {
      return getProducts();
    },
    {
      onSuccess: (data) => {
        setProductData(data);
      },
      cacheTime: 0,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  // const {
  //   isFetching: isLoading2,
  //   data: response2,
  // } = useQuery(
  //   ["productsRenderingFilesAndImages", { responseProducts: productData }],
  //   getImages,
  //   {
  //     onSuccess: (data) => {
  //       const data2 = [...data];
  //       setProductDataAndImage(data2);
  //     },
  //     enabled: !!productData,
  //     cacheTime: 0,
  //     staleTime: Infinity,
  //     keepPreviousData: true,
  //     refetchOnWindowFocus: false,
  //   }
  // );


  return (
  
        <QueryProductResponseContext.Provider
          value={{ isLoading: isLoading, refetch, response, query }}
        >
          {children}
        </QueryProductResponseContext.Provider>
  );
};

const useQueryResponse = () => useContext(QueryProductResponseContext);

const useQueryResponseProductData = () => {
  const { response } = useQueryResponse();
  if (!response) {
    return [];
  }

  return response || [];
};

const useQueryResponseProductLoading = () => {
  const { isLoading } = useQueryResponse();
  return isLoading;
};

const useQueryResponseProductLoading2 = () => {
  const { isLoading2 } = useQueryResponse();
  return isLoading2;
};

export {
  QueryProductResponseProvider,
  useQueryResponseProductLoading2,
  useQueryResponse,
  useQueryResponseProductData,
  useQueryResponseProductLoading,
};
