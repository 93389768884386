import React, { useState,useMemo,useEffect } from "react";
import Aside from "./components/sidebar";
import Main from "./components/main";
import { classSelector } from "../../../../_clientPage/assets/helper/AssetHelper";
import { useLocation } from "react-router-dom";
import { Outlet } from 'react-router-dom'
import clsx from "clsx";
import { useNotification } from "../../../../app/providers/globalProviders/Notification/NotificationProvider";
import Notification from "../components/notification/Notification";
function SidebarLayout() {
  const [collapsed, setCollapsed] = useState(false);
  const [toggled, setToggled] = useState(false);
  let location = useLocation();

  const handleToggleSidebar = (value) => {
    setToggled(!toggled);
  };

  const {notification,setNotification} = useNotification();
  const showNotification = useMemo(() => !!notification, [notification])

  const handleResetNotification = () => {
    setNotification({ status: 'success', message: null })
  }

  useEffect(() => {
    let timeout;

    if (notification?.message !== null) {
      timeout = setTimeout(() => {
        handleResetNotification()
      }, 3000)
    } else {
      clearTimeout(timeout)
    }

    return () => clearTimeout(timeout)
  }, [notification])


  return (
    <div

      className={clsx([`app webshop-pages ${classSelector(location.pathname)} `], { "toggled": toggled })}
    >
      <>
        <Aside
          collapsed={collapsed}
          toggled={toggled}
          handleToggleSidebar={handleToggleSidebar}
        />

        <Main
          toggled={toggled}
          collapsed={collapsed}
          handleToggleSidebar={() => handleToggleSidebar}
          locationName={location.pathname}
        >
          {<Outlet />}
        </Main>
      </>
      {!!notification && (
        <Notification showNotification={showNotification} {...notification} />
      )}
    </div>
  );
}

export default SidebarLayout;
