import PageTitleSelectorWebshop from './title/webshopHeader/pageTitleWebshop';
import PageTitleSelectorBasic from './title/basicHeader/pageTitleBasic';
import MapRoutes from './title/pageMapRouteComponent';


export function titleSelector(param) {

    if (param.includes("/installation-addresses")) {
        return (<PageTitleSelectorBasic title="Warehouses" />)
    }
    if (param.includes("/your-warehouses")) {
        return (<PageTitleSelectorBasic title="Warehouses" />)
    }
    if (param.includes("/best-selling-items")) {
        return (<PageTitleSelectorBasic title="Best Selling Items" />)
    }
    if (param.includes("/confirmed-orders")) {
        return (<PageTitleSelectorBasic title="Warehouses" />)
    }
    if (param.includes("/pending-orders")) {
        return (<PageTitleSelectorBasic title="Warehouses" />)
    }
    if (param.includes("/previous-orders")) {
        return (<PageTitleSelectorBasic title="Warehouses" />)
    }
    if (param.includes("/problematic-orders")) {
        return (<PageTitleSelectorBasic title="Warehouses" />)
    }
    if (param.includes("/select-brand")) {
        return (<PageTitleSelectorWebshop num={"01"} title={"SELECT BRAND"} />)
    }
    if (param.includes("/select-type")) {
        return (<>
            <MapRoutes />
            <PageTitleSelectorWebshop num={"02"} title={"SELECT TYPE"} />
        </>)
    }

    if (param.includes("/select-product")) {
        return (<>
            <MapRoutes />
            <PageTitleSelectorWebshop num={"03"} title={"SELECT PRODUCT"} />
        </>)
    }
    if(param.includes("/product")){
    return (<>
        <PageTitleSelectorWebshop num={"04"} title={"PRODUCT QUANTITY"} />
    </>)}
}