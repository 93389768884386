import axios from 'axios'


const APPZ4_BACKEND_URL = process.env.REACT_APP_APPZ4_BACKEND_URL
export const GET_BRANDS_URL = `${APPZ4_BACKEND_URL}/manufacturer`
const GET_IMAGES_URL = `${APPZ4_BACKEND_URL}/file/`
const getImageFile = (response) => {
  let resultImage = btoa(
    new Uint8Array(response.data)
      .reduce((data, byte) => data + String.fromCharCode(byte), '')
  );

  return `data:${response.headers['content-type'].toLowerCase()};base64,${resultImage}`;
}
const getBrands = () => {
  return axios
    .get(`${GET_BRANDS_URL}`)
    .then((d) => d.data.data?.manufacturers)
}

const getImages = async ({ queryKey }) => {

  const [, { responseBrands }] = queryKey;


  const brandsArray = [...responseBrands];
  const arrayList = []

  return await Promise.all(brandsArray.map(async (imageItem, index2) => {

    return await axios
    
      .get(`${GET_IMAGES_URL}${btoa(imageItem.img)}`, { responseType: 'arraybuffer' })
      .then(response => {
        imageItem.img_src = ""
        let image = btoa(
          new Uint8Array(response.data)
            .reduce((data, byte) => data + String.fromCharCode(byte), '')
        );
        imageItem.img_src = `data:${response.headers['content-type'].toLowerCase()};base64,${image}`;
        return imageItem
      })
  }))




  return brandsArray
}

export { getBrands, getImages }
