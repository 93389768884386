import { useNavigate } from "react-router-dom";
import React, { useState } from "react";
import * as Yup from "yup";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import { forgottPassword } from "../core/_requests";
import { errorHandle } from "../../../../_clientPage/assets/helper/error-handler/errorHandler";

const initialValues = {
  email: "admin@demo.com",
};

const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email("Wrong email format")
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Email is required"),
});

const ForgotPassword = () => {
  const [success, setSuccess] = useState(false);
  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      try {
        await forgottPassword(values.email, window.location.href + "/")

        setSuccess(true)

        // setTimeout(() => {
        //   navigate("/");
        // }, 1000);
      } catch (error) {
        setStatus('The details is incorrect')
        const errorText = errorHandle(error)
        setStatus(errorText)
        setTimeout(() => {
          setStatus("")
        }, 2500)
      } finally {
        setSubmitting(false)
      }
    },
  });
  const navigate = useNavigate();
  return (
    <>
      <div className="bg-image-container">
        <div className="bg-image"></div>
      </div>
      <div className="bg-image__cover-overlay"></div>
      <div className="page">
        <section className="section--auths-boxes">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">

                <div className="row cols-wrapper">
                  <div className="col-11 col-xl-6 overlay-col">
                    <div className="overlay-container">
                      <div>
                        <div className="logo-wrapper">
                          <div className="logo-container">
                            <div className="logo"></div>
                          </div>
                        </div>
                        <h3>WELCOME IN THE WORLD OF</h3>
                        <h1>SOLAR HEROES!</h1>
                      </div>
                    </div>
                  </div>
                  <div className="col-11 col-xl-6 form-col">
                    <div className="form-container">
                      <div className="form_ver-red-wrapper form__light">

                        <form
                          className="cutted-corner auths-form forgotten-password-corner"
                          noValidate
                          onSubmit={formik.handleSubmit}
                        >
                          {/* {hasErrors === true && (
                            <div className="mb-lg-15 alert alert-danger">
                              <div className="alert-text font-weight-bold">
                                Sorry, looks like there are some errors detected, please
                                try again.
                              </div>
                            </div>
                          )}

                          {hasErrors === false && (
                            <div className="mb-10 bg-light-success p-8 rounded">
                              <div className="text-success">
                                Sent password reset. Please check your email
                              </div>
                            </div>
                          )} */}

                          {formik.status && (
                            <div className='alert alert-danger custom-danger mt-6'>
                              <div className='alert-text font-weight-bold'>Sorry, looks like there are some errors detected, please
                                try again!</div>
                            </div>
                          )}

                          {success && (
                            <div className='mb-10 bg-light-success p-4 rounded mt-6'>
                              <div className='text-success'>Sent password reset. Please check your email!</div>
                            </div>
                          )}
                          <h3 className="section-title--big">
                            Forgotten password
                          </h3>
                          <div className="auth-input">
                            <p>Please enter your email address</p>
                          </div>
                          <div className="gradient-border auth-input normal-input">
                            <input
                              type="email"
                              placeholder=""
                              autoComplete="off"
                              {...formik.getFieldProps("email")}
                              className={clsx(
                                "form-control form-control-lg form-control-solid",
                                {
                                  "is-invalid":
                                    formik.touched.email && formik.errors.email,
                                },
                                {
                                  "is-valid":
                                    formik.touched.email &&
                                    !formik.errors.email,
                                }
                              )}
                            />

                          </div>
                          {formik.touched.email && formik.errors.email && (
                            <div className="fv-plugins-message-container">
                              <div className="fv-help-block">
                                <span role="alert">
                                  {formik.errors.email}
                                </span>
                              </div>
                            </div>
                          )}
                          <div className="button-container auth-input">
                            <button
                              type="submit"
                              id="kt_password_reset_submit"
                              className="global-button"
                            >
                              {!formik.isSubmitting && <span className="indicator-label">Submit</span>}
                              {formik.isSubmitting && (
                                <span className="indicator-progress">
                                  Please wait...
                                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                </span>
                              )}
                            </button>
                          </div>
                          <div className="already-account-wrapper mt-2">
                            <Link to="/">Back to Sign in</Link>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

    </>
  );
};
export default ForgotPassword;
