import React, { useState } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { addressRegister } from '../requests'
import { useQueryResponseCountryData } from '../../../../../../app/providers/globalProviders/Country/core/QueryResponseProviderCountry'
import CustomSelect from "../../../components/customSelect/select";
import { useQueryResponse } from '../../../../../../app/modules/addresses/core/QueryResponseProviderAddresses'
import { errorHandle } from '../../../../../assets/helper/error-handler/errorHandler'
import clsx from 'clsx'

const addressDetailsSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    phone: Yup.string().required('Phone is required'),
    country_id: Yup.string().required('Country is required'),
    city: Yup.string().required('City is required'),
    zip: Yup.string().required('Zip is required'),
    street: Yup.string().required('Street is required'),
    apartment: Yup.string().required('Apartment is required'),
    warehouse: Yup.string().required('Warehouse is required'),
})

const initialValues = {
    name: "",
    phone: "",
    country_id: undefined,
    city: "",
    zip: "",
    street: "",
    apartment: "",
    warehouse: undefined,
    default: false
};

const warehouseData = [
    { id: 0, name: "Warehouse" },
    { id: 1, name: "Installation address" },
];

const NewAddressModalContent = ({ onCancel }) => {


    const [success, setSuccess] = useState(false)
    const { refetch } = useQueryResponse();
    const countriesData = useQueryResponseCountryData();


    const formik = useFormik({
        initialValues,
        validationSchema: addressDetailsSchema,
        onSubmit: async (values, { setStatus, setSubmitting }) => {
            setSubmitting(true)
            try {
                const { data: resp } = await addressRegister(
                    values
                )
                setTimeout(() => {
                    onCancel()
                    refetch()
                }, 1000)
            } catch (error) {
                setStatus('The details is incorrect')
                const errorText = errorHandle(error)
                setStatus(errorText)
                setTimeout(() => {
                    setStatus("")
                }, 2500)
            } finally {
                setSubmitting(false)
            }
        },
    })

    return (
        <div className="form_ver-red-wrapper add-warehouse-modal">
            <div className="confirmed-bot__wrapper __modal-bot">
                <div className="bot-modal"></div>
            </div>
            <form onSubmit={formik.handleSubmit} noValidate className="cutted-corner auths-form add-warehouse" action="#">
                <h3 className="section-title--big">Add new address</h3>
                <div className="gradient-border input-80 normal-input auth-input">
                    <input
                        type='text'
                        className={clsx(
                            "form-control form-control-lg form-control-solid mb-lg-0",
                            {
                                "is-invalid":
                                    formik.touched.name && formik.errors.name,
                            },
                            {
                                "is-valid":
                                    formik.touched.name &&
                                    !formik.errors.name,
                            }
                        )}
                        placeholder='Name'
                        {...formik.getFieldProps('name')}
                    />
                </div>
                {formik.touched.name && formik.errors.name && (
                    <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.name}</div>
                    </div>
                )}
                <div className="gradient-border input-50 auth-input">
                    <input
                        type='text'
                        className={clsx(
                            "form-control form-control-lg form-control-solid mb-lg-0",
                            {
                                "is-invalid":
                                    formik.touched.phone && formik.errors.phone,
                            },
                            {
                                "is-valid":
                                    formik.touched.phone &&
                                    !formik.errors.phone,
                            }
                        )}
                        placeholder='Phone'
                        {...formik.getFieldProps('phone')}
                    />
                </div>
                {formik.touched.phone && formik.errors.phone && (
                    <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.phone}</div>
                    </div>
                )}
                <div className="gradient-border input-80 normal-input auth-input">
                    <input
                        type='text'
                        className={clsx(
                            "form-control form-control-lg form-control-solid mb-lg-0",
                            {
                                "is-invalid":
                                    formik.touched.city && formik.errors.city,
                            },
                            {
                                "is-valid":
                                    formik.touched.city &&
                                    !formik.errors.city,
                            }
                        )}
                        placeholder='City'
                        {...formik.getFieldProps('city')}
                    />
                </div>
                {formik.touched.city && formik.errors.city && (
                    <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.city}</div>
                    </div>
                )}
                <div className="input__wrapper">
                    <div className="input-50" style={{ marginTop: "20px" }}>
                        <div className="gradient-border auth-input">
                            <input
                                type='text'
                                className={clsx(
                                    "form-control form-control-lg form-control-solid mb-lg-0",
                                    {
                                        "is-invalid":
                                            formik.touched.street && formik.errors.street,
                                    },
                                    {
                                        "is-valid":
                                            formik.touched.street &&
                                            !formik.errors.street,
                                    }
                                )}
                                placeholder='Street'
                                {...formik.getFieldProps('street')}
                            />
                        </div>
                        {formik.touched.street && formik.errors.street && (
                            <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>{formik.errors.street}</div>
                            </div>
                        )}
                    </div>

                    <div className="input-50" style={{ marginTop: "20px" }}>
                        <div className="gradient-border auth-input">
                            <input
                                type='text'
                                className={clsx(
                                    "form-control form-control-lg form-control-solid mb-lg-0",
                                    {
                                        "is-invalid":
                                            formik.touched.apartment && formik.errors.apartment,
                                    },
                                    {
                                        "is-valid":
                                            formik.touched.apartment &&
                                            !formik.errors.apartment,
                                    }
                                )}
                                placeholder='Apartment'
                                {...formik.getFieldProps('apartment')}
                            />
                        </div>
                        {formik.touched.apartment && formik.errors.apartment && (
                            <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>{formik.errors.apartment}</div>
                            </div>
                        )}
                    </div>

                </div>

                <div className="input__wrapper flex-row">
                    <div className="gradient-border auth-input">
                        <CustomSelect
                            defaultText={"Select a country"}
                            optionsList={countriesData}
                            formik={formik}
                            fieldName={"country_id"}
                        ></CustomSelect>
                    </div>
                    {formik.touched.country && formik.errors.country && (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                                <span role="alert">
                                    {formik.errors.country}{" "}
                                </span>
                            </div>
                        </div>
                    )}
                    <div className="gradient-border auth-input">
                        <CustomSelect
                            defaultText={"Select address"}
                            optionsList={warehouseData}
                            formik={formik}
                            fieldName={"warehouse"}
                        ></CustomSelect>
                    </div>

                    {formik.touched.warehouse && formik.errors.warehouse && (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                                <span role="alert">
                                    {formik.errors.warehouse}{" "}
                                </span>
                            </div>
                        </div>
                    )}
                </div>

                <div className="gradient-border input-25 auth-input">
                    <input
                        type='text'
                        className={clsx(
                            "form-control form-control-lg form-control-solid mb-lg-0",
                            {
                                "is-invalid":
                                    formik.touched.zip && formik.errors.zip,
                            },
                            {
                                "is-valid":
                                    formik.touched.zip &&
                                    !formik.errors.zip,
                            }
                        )}
                        placeholder='Zip'
                        {...formik.getFieldProps('zip')}
                    />
                </div>
                {formik.touched.zip && formik.errors.zip && (
                    <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.zip}</div>
                    </div>
                )}
                <div className="aszf-container auth-input">
                    <input type="checkbox" id="check" {...formik.getFieldProps('default')} />
                    <label htmlFor="check"></label><div className="aszf-link">Set as default</div>
                </div>
                <div className="bottom-side">
                    {formik.status && (
                        <div className='alert alert-danger mt-6'>
                            <div className='alert-text font-weight-bold'>{formik.status}</div>
                        </div>
                    )}
                    {success && (
                        <div className='mb-10 bg-light-success p-4 rounded mt-6'>
                            <div className='text-success'>Changes were successfully saved!</div>
                        </div>
                    )}
                    <div className="button-wrapper">
                        <div className="button-container auth-input">
                            {/* <Button type="button" classes="global-button" child={"SAVE"} clickFunction={onCancel}></Button> */}
                            <button
                                type="submit"
                                id="kt_sign_in_submit"
                                className="global-button"
                                disabled={formik.isSubmitting}
                            >
                                {!formik.isSubmitting && (
                                    <span className="indicator-label">Save</span>
                                )}
                                {formik.isSubmitting && (
                                    <span
                                        className="indicator-progress"
                                        style={{ display: "block" }}
                                    >
                                        Please wait...
                                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                    </span>
                                )}
                            </button>
                        </div>
                        <div className="button-container auth-input">
                            <button type="button" className="global-button grey-background" onClick={onCancel}>Close</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default NewAddressModalContent;