
function isDatabaseError(obj) {
    return obj !== undefined && obj !== null && obj !== ''
  }

  function errorHandle(obj) {

    const err = obj
        const errorsList = Object.values(err.response?.data)[2];
    const isErrorMessage = isDatabaseError(errorsList)

    if(errorsList && typeof errorsList === 'string'){
      return (<div>{errorsList}</div>)
    }

    if(isErrorMessage)
    {

      
        const errors = Object.values(errorsList)

       
        return(<ul>
        {errors.map((err) => {
          return(<li className="error__item" key={err}>{err}</li>)
        })}
      </ul>)
    }
    return <div>Server error!</div>
  }

  export {
    errorHandle,
  }
  