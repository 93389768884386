import axios from "axios"
import { isDefault } from "../../../../_clientPage/assets/helper/AssetHelper"


const APPZ4_BACKEND_URL = process.env.REACT_APP_APPZ4_BACKEND_URL
const ADDRESS_URL = `${APPZ4_BACKEND_URL}/address`
export const EDIT_ADDRESS_URL = `${APPZ4_BACKEND_URL}/address/`


const deleteAddress = (addressId) => {
    return axios.delete(`${ADDRESS_URL}/${addressId}`).then(() => { })
}

const getAddressById = (id) => {
    return axios
        .get(`${APPZ4_BACKEND_URL}/user/${id}/addresses`)
        .then((d) => d.data.data?.addresses)
}

  

export function editAddress(
    Address
) {
    return axios.put(EDIT_ADDRESS_URL + Address.id, {
        name: Address.name,
        phone: Address.phone,
        zip: Address.zip,
        country_id: Address.country_id,
        city: Address.city,
        street: Address.street,
        apartment: Address.apartment,
        warehouse: Address.warehouse,
        default: isDefault(Address.default)
    })
}

export function addressRegister(
    User
  ) {
    return axios.post(ADDRESS_URL, {
      name: User.name,
      phone: User.phone,
      zip: User.zip,
      country_id: User.country_id,
      city: User.city,
      street: User.street,
      apartment: User.apartment,
      warehouse: User.warehouse,
      default: isDefault(User.default)
    })
  }

  
export { deleteAddress, getAddressById }