import { useEffect, useState } from 'react'

export const toAbsoluteUrl = (pathname) => process.env.PUBLIC_URL + pathname


function groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
        const key = keyGetter(item);
        const collection = map.get(key);
        if (!collection) {
            map.set(key, [item]);
        } else {
            collection.push(item);
        }
    });
    return map;
}

function useDebounce(value, delay) {
    // State and setters for debounced value
    const [debouncedValue, setDebouncedValue] = useState(value)
    useEffect(
        () => {
            // Update debounced value after delay
            const handler = setTimeout(() => {
                setDebouncedValue(value)
            }, delay)
            // Cancel the timeout if value changes (also on delay change or unmount)
            // This is how we prevent debounced value from updating if value is changed ...
            // .. within the delay period. Timeout gets cleared and restarted.
            return () => {
                clearTimeout(handler)
            }
        },
        [value, delay] // Only re-call effect if value or delay changes
    )
    return debouncedValue
}

function isWarehouse(item) {
    if (item == 0) {
        return true;
    }
    return false;
}

export function isInstallationAdress(item) {
    if (item == 1) {
        return true;
    }
    return false;
}

export function isDefault(item) {
    if (item == "false") {
        return 0;
    }
    return 1;
}

export function classSelector(param) {

    if (param.includes("/select-brand")) {
        return "select-brand__page"
    }
    // if (param.includes("/confirmed-orders")) {
    //     return "select-brand__page"
    // }
    // if (param.includes("/attention-required-orders")) {
    //     return "problematic-orders-page";
    // }
    // if (param.includes("/pending-orders")) {
    //     return "warehouse-confirmed-page pending-order__page";
    // }
    // if (param.includes("/previous-orders")) {
    //     return "warehouse-confirmed-page pending-order__page previous-order__page";
    // }
    if (param.includes("/best-selling-items")) {
        return "best-selling-items-page";
    }
    if (param.includes("/select-type")) {
        return "select-brand__page __select-type__page"
    }
    if (param.includes("/select-product")) {
        return "best-selling-items-page __select-product__page"
    }
    if (param.includes("/order-summary")) {
        return "order-summary__page"
    }
    if (param.includes("/cart")) {
        return "cart-page"
    }
    if (param.includes("/shipping")) {
        return "shipping-page"
    }
    if (param.includes("/product")) {
        return "select-products-page"
    }
    if (param.includes("/success-pay/cart")) {
        return "success-pay-page"
    }
}

export { groupBy, useDebounce, isWarehouse }