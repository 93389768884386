import { Link } from 'react-router-dom';
import TooltipIcon from "../../../../_clientPage/assets/images/graphs/tooltip.svg";
import {
  useNavigate,
} from "react-router-dom";
import CustomSelect from "../../../../_clientPage/layout/partials/components/customSelect/select";
import { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { register } from "../core/_requests";
import clsx from "clsx";
import { useQueryResponseCountryData } from "../../../providers/globalProviders/Country/core/QueryResponseProviderCountry";
import { useQueryResponseLanguageData } from "../../../providers/globalProviders/Language/core/QueryResponseProviderLang";
import { errorHandle } from "../../../../_clientPage/assets/helper/error-handler/errorHandler";
import EyeButton from '../../../../_clientPage/layout/partials/components/eye-button/EyeButton';
import { tradeData } from '../../../../_clientPage/helper/crud-helper/consts';
const initialValues = {
  name: "",
  email: "",
  password: "",
  changepassword: "",
  acceptTerms: false,
  country: undefined,
  phone: "",
  vatNumber: "",
  language: undefined,
  trade: undefined,
};

const registrationSchema = Yup.object().shape({
  country: Yup.string().required("Country is required"),
  language: Yup.string().required("Language is required"),
  vatNumber: Yup.string().required("Vat number is required"),
  trade: Yup.string().required("Trade is required"),
  name: Yup.string()
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Name is required"),
  email: Yup.string()
    .email("Wrong email format")
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Email is required"),
  password: Yup.string()
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Password is required"),
  phone: Yup.string().required("Phone is required"),
  changepassword: Yup.string()
    .required("Password confirmation is required")
    .when("password", {
      is: (val) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf(
        [Yup.ref("password")],
        "Password and Confirm Password didn't match"
      ),
    }),
  acceptTerms: Yup.bool().required("You must accept the terms and conditions").oneOf([true], "The terms and conditions must be accepted."),
});

const Registration = () => {
  const [success, setSuccess] = useState(false);
  const [passwordType, setPasswordType] = useState('password');

  const countriesData = useQueryResponseCountryData();
  const languagesData = useQueryResponseLanguageData();

  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: async (values, { setStatus, setSubmitting, resetForm }) => {
      setSubmitting(true);
      try {

        const { data: resp } = await register(
          values.name,
          values.phone,
          values.email,
          values.password,
          values.country,
          values.vatNumber,
          values.language,
          values.trade
        );

        setSuccess(true)
        setTimeout(() => {
          navigate("/");
        }, 1000);

      } catch (error) {
        setStatus('The details is incorrect')
        const errorText = errorHandle(error)
        setStatus(errorText)
        setTimeout(() => {
          setStatus("")
        }, 2500)
      } finally {
        setSubmitting(false)
      }
    },
  });

  const navigate = useNavigate();

  return (
    <>
      <div className="bg-image-container">
        <div className="bg-image"></div>
      </div>
      <div className="bg-image__cover-overlay"></div>
      <div className="page">
        <section className="section--auths-boxes">
          <div className="container reg-container">
            <div className="row">
              <div className="col-lg-12">
                <div className="row cols-wrapper">
                  <div className="col-11 col-xl-6 overlay-col">
                    <div className="overlay-container">
                      <div>
                        <div className="logo-wrapper">
                          <div className="logo-container">
                            <div className="logo"></div>
                          </div>
                        </div>
                        <h3>WELCOME IN THE WORLD OF</h3>
                        <h1>SOLAR HEROES!</h1>
                      </div>
                    </div>
                  </div>
                  <div className="col-11 col-xl-6 form-col">
                    <div className="form-container registrations-form">
                      <div className="form_ver-red-wrapper form__light">
                        <form
                          className="cutted-corner auths-form signup"
                          onSubmit={formik.handleSubmit}
                          noValidate
                        >
                          <h3 className="section-title--big">Sign up</h3>
                          <div className="gradient-border normal-input auth-input">
                            <input
                              type="text"
                              placeholder="NAME"
                              {...formik.getFieldProps("name")}
                              className={clsx(
                                "form-control form-control-lg form-control-solid",
                                {
                                  "is-invalid":
                                    formik.touched.name && formik.errors.name,
                                },
                                {
                                  "is-valid":
                                    formik.touched.name && !formik.errors.name,
                                }
                              )}
                            />
                          </div>
                          {formik.touched.name && formik.errors.name && (
                            <div className="fv-plugins-message-container">
                              <div className="fv-help-block">
                                <span role="alert">{formik.errors.name}</span>
                              </div>
                            </div>
                          )}
                          <div className="gradient-border auth-input normal-input">
                            <input
                              type="text"
                              {...formik.getFieldProps("phone")}
                              className={clsx(
                                "form-control form-control-lg form-control-solid",
                                {
                                  "is-invalid":
                                    formik.touched.phone && formik.errors.phone,
                                },
                                {
                                  "is-valid":
                                    formik.touched.phone &&
                                    !formik.errors.phone,
                                }
                              )}
                              placeholder="PHONE"
                            />
                          </div>
                          {formik.touched.phone && formik.errors.phone && (
                            <div className="fv-plugins-message-container">
                              <div className="fv-help-block">
                                <span role="alert">{formik.errors.phone}</span>
                              </div>
                            </div>
                          )}

                          <div className="input__wrapper auth-input flex-row">
                            <div>
                              <div className="gradient-border auth-input w-100">
                                <CustomSelect
                                  defaultText={"Select a country"}
                                  optionsList={countriesData}
                                  formik={formik}
                                  fieldName={"country"}
                                  searchable={true}
                                ></CustomSelect>
                              </div>
                              {formik.errors.country && (
                                <div className="fv-plugins-message-container w-100">
                                  <div className="fv-help-block">
                                    <span role="alert">
                                      {formik.errors.country}{" "}
                                    </span>
                                  </div>
                                </div>
                              )}
                            </div>
                            <div>
                              <div className="gradient-border auth-input w-100">
                                <CustomSelect
                                  defaultText={"Select a language"}
                                  optionsList={languagesData}
                                  formik={formik}
                                  fieldName={"language"}
                                ></CustomSelect>
                              </div>
                              {formik.touched.language && formik.errors.language && (
                                <div className="fv-plugins-message-container w-100">
                                  <div className="fv-help-block">
                                    <span role="alert">
                                      {formik.errors.language}
                                    </span>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="gradient-border auth-input">
                            <input
                              type="email"
                              autoComplete="off"
                              {...formik.getFieldProps("email")}
                              className={clsx(
                                "form-control form-control-lg form-control-solid",
                                {
                                  "is-invalid":
                                    formik.touched.email && formik.errors.email,
                                },
                                {
                                  "is-valid":
                                    formik.touched.email &&
                                    !formik.errors.email,
                                }
                              )}
                              placeholder="EMAIL"
                            />
                          </div>
                          {formik.touched.email && formik.errors.email && (
                            <div className="fv-plugins-message-container">
                              <div className="fv-help-block">
                                <span role="alert">{formik.errors.email}</span>
                              </div>
                            </div>
                          )}
                          <div className="gradient-border auth-input">
                            {" "}
                            <input
                              type={passwordType}
                              autoComplete="off"
                              {...formik.getFieldProps("password")}
                              className={clsx(
                                "form-control form-control-lg form-control-solid",
                                {
                                  "is-invalid":
                                    formik.touched.password &&
                                    formik.errors.password,
                                },
                                {
                                  "is-valid":
                                    formik.touched.password &&
                                    !formik.errors.password,
                                }
                              )}
                              placeholder="PASSWORD"
                            />

                            <EyeButton passwordType={passwordType} setPasswordType={setPasswordType} password={formik.values.password}></EyeButton>
                          </div>
                          {formik.touched.password && formik.errors.password && (
                            <div className="fv-plugins-message-container">
                              <div className="fv-help-block">
                                <span role="alert">
                                  {formik.errors.password}
                                </span>
                              </div>
                            </div>
                          )}
                          <div className="gradient-border auth-input">
                            <input
                              placeholder="PASSWORD AGAIN"
                              type="password"
                              autoComplete="off"
                              {...formik.getFieldProps("changepassword")}
                              className={clsx(
                                "form-control form-control-lg form-control-solid",
                                {
                                  "is-invalid":
                                    formik.touched.changepassword &&
                                    formik.errors.changepassword,
                                },
                                {
                                  "is-valid":
                                    formik.touched.changepassword &&
                                    !formik.errors.changepassword,
                                }
                              )}
                            />
                          </div>
                          {formik.touched.changepassword &&
                            formik.errors.changepassword && (
                              <div className="fv-plugins-message-container">
                                <div className="fv-help-block">
                                  <span role="alert">
                                    {formik.errors.changepassword}
                                  </span>
                                </div>
                              </div>
                            )}
                          <div className="tooltip-icon-wrapper auth-input">
                            <div className="gradient-border">
                              <input
                                type="text"
                                placeholder="VAT NR."
                                className="form-control"
                                {...formik.getFieldProps("vatNumber")}
                              />
                              <img
                                alt="icon"
                                className="tooltip-icon"
                                src={TooltipIcon}
                              ></img>
                            </div>
                            {formik.touched.vatNumber &&
                              formik.errors.vatNumber && (
                                <div className="fv-plugins-message-container">
                                  <div className="fv-help-block">
                                    <span role="alert">
                                      {formik.errors.vatNumber}
                                    </span>
                                  </div>
                                </div>
                              )}
                          </div>
                          <div className="gradient-border auth-input">
                            <CustomSelect
                              defaultText={"Select a type of trader"}
                              optionsList={tradeData}
                              formik={formik}
                              fieldName={"trade"}
                            ></CustomSelect>
                          </div>
                          {formik.touched.trade && formik.errors.trade && (
                            <div className="fv-plugins-message-container">
                              <div className="fv-help-block">
                                <span role="alert">
                                  {formik.errors.trade}
                                </span>
                              </div>
                            </div>
                          )}
                  
                          <div className="aszf-container auth-input">
                            <input
                              type="checkbox"
                              id="check"
                              {...formik.getFieldProps("acceptTerms")}
                            />
                            <label htmlFor="check"></label>
                            <Link className="aszf-link" to="/signup">
                              Accept terms
                            </Link>
                          </div>
                          {formik.touched.acceptTerms &&
                            formik.errors.acceptTerms && (
                              <div className="fv-plugins-message-container">
                                <div className="fv-help-block">
                                  <span role="alert">
                                    {formik.errors.acceptTerms}
                                  </span>
                                </div>
                              </div>
                            )}


                          {formik.status && (
                            <div className='alert alert-danger custom-danger'>
                              <div className='alert-text font-weight-bold'>{formik.status}</div>
                            </div>
                          )}


                          {success && (
                            <div className="mb-lg-15 success text-center badge-light-success">
                              <div className="success-text registration-success font-weight-bold fs-6">
                                Successful registration!
                              </div>
                            </div>
                          )}

                          <button
                            type="submit"
                            id="kt_sign_in_submit"
                            className="global-button"
                            disabled={formik.isSubmitting}
                          >
                            {!formik.isSubmitting && (
                              <span className="indicator-label">Registration</span>
                            )}
                            {formik.isSubmitting && (
                              <span
                                className="indicator-progress"
                                style={{ display: "block" }}
                              >
                                Please wait...
                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                              </span>
                            )}
                          </button>
                          <div className="already-account-wrapper">
                            Already have an account?{" "}
                            <Link to="/auth">Sign in</Link>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      {/* <NavigationBar prevLink={"/confirmed-password"} nextLink={"/signup-complated"} /> */}
    </>
  );
};
export default Registration;
