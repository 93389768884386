import React from 'react';
import MobileHeader from '../../header/mobilHeader'
import { titleSelector } from '../../../../../_clientPage/layout/partials/header/header';
import Cart from '../../components/cartIcon/cart'

const Main = ({
  collapsed,
  handleToggleSidebar,
  children,
  locationName
}) => {


  return (
    <main className={`${collapsed ? "collapsed" : "normal"}`}>
      <div className="page-background"></div>
      <MobileHeader handleToggleSidebar={handleToggleSidebar} />
      <div className='page-content --padding-60 container' id="page-content">
        <div>
          <div className="row">
            <div className="col">

              <Cart classes={"cart__icon --fixed screen-lg"} classesWrapper={"header-imgs-wrapper right-logo"} />

            </div>
          </div>
          <div className="row titles-wrapper route-wrapper">
            
            {titleSelector(locationName)}
          </div>
        </div>
        {children}
      </div>
    </main>
  );
};

export default Main;