/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useState, createContext } from "react";
import { useQuery } from "react-query";
import { initialQueryResponse } from "../../../../_clientPage/assets/helper/model";
import { getTypes, getImages, GET_TYPES_URL } from "./_requests";
import { isNotEmpty } from "../../../../_clientPage/helper/crud-helper/helper";
import { useLocation } from "react-router-dom";
const QueryTypeResponseContext = createContext(initialQueryResponse);
const QueryTypeResponseProvider = ({ children }) => {
  const [query, setQuery] = useState("/types");
  const [typeData, setTypeData] = useState(undefined);
  const [typeDataAndImage, setTypeDataAndImage] = useState(undefined);

  const { search } = useLocation()
  const brandId = new URLSearchParams(search).get("brandId")

  const enabledQuery = isNotEmpty(brandId)

  const { isFetching: isLoading, refetch,data: response } = useQuery(
    `${GET_TYPES_URL}`,
    () => {
      return getTypes();
    },
    {
      onSuccess: (data) => {
        setTypeData(data);
      },
      cacheTime: 0,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      enabled:enabledQuery
    }
  );


  return (
  
        <QueryTypeResponseContext.Provider
          value={{ isLoading: isLoading, refetch, response, query }}
        >
          {children}
        </QueryTypeResponseContext.Provider>
  );
};

const useQueryResponse = () => useContext(QueryTypeResponseContext);

const useQueryResponseTypeData = () => {
  const { response } = useQueryResponse();
  if (!response) {
    return [];
  }

  return response || [];
};

const useQueryResponseTypeLoading = () => {
  const { isLoading } = useQueryResponse();
  return isLoading;
};
export {
  QueryTypeResponseProvider,
  useQueryResponse,
  useQueryResponseTypeData,
  useQueryResponseTypeLoading,
};
