import axios from 'axios'

const APPZ4_BACKEND_URL = process.env.REACT_APP_APPZ4_BACKEND_URL
export const GET_CART_URL = `${APPZ4_BACKEND_URL}/cart`

const getCart = (id) => {
  return axios
    .get(`${GET_CART_URL}/${id}`)
    .then((d) => d.data.data?.cart)
}


export { getCart }
