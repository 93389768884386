import axios from 'axios'


const APPZ4_BACKEND_URL = process.env.REACT_APP_APPZ4_BACKEND_URL
export const GET_TYPES_URL = `${APPZ4_BACKEND_URL}/productType`
const GET_IMAGES_URL = `${APPZ4_BACKEND_URL}/file/`
const getImageFile = (response) => {
  let resultImage = btoa(
    new Uint8Array(response.data)
      .reduce((data, byte) => data + String.fromCharCode(byte), '')
  );

  return `data:${response.headers['content-type'].toLowerCase()};base64,${resultImage}`;
}
const getTypes = () => {
  return axios
    .get(`${GET_TYPES_URL}`)
    .then((d) => d.data.data?.productTypes)
}

const getImages = async ({ queryKey }) => {

  const [, { responseType }] = queryKey;


  const typesArray = [...responseType];
  const arrayList = []
  console.log("typeArray: ",typesArray)
  return await Promise.all(typesArray.map(async (imageItem, index2) => {
    imageItem.img_src = ""
    console.log("imageItem: ",imageItem.img)
    console.log("btoa: ",btoa(imageItem.img))
    await axios
    
      .get(`${GET_IMAGES_URL}${btoa(imageItem.img)}`)
      .then(response => {
        console.log("response: ",response)
        imageItem.img_src = ""
        let image = btoa(
          new Uint8Array(response.data)
            .reduce((data, byte) => data + String.fromCharCode(byte), '')
        );
        imageItem.img_src = `data:${response.headers['content-type'].toLowerCase()};base64,${image}`;
        return imageItem
      })
  }))

}

export { getTypes, getImages }
