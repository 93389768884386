import axios from 'axios'

const APPZ4_BACKEND_URL = process.env.REACT_APP_APPZ4_BACKEND_URL


export const REGISTER_URL = `${APPZ4_BACKEND_URL}/user`


export const LOGIN_URL_APPZ4 = `${APPZ4_BACKEND_URL}/login`
export const FORGOT_PASSWORD_URL = `${APPZ4_BACKEND_URL}/user/forgotten-password`
export const REQUEST_PASSWORD_URL = `${APPZ4_BACKEND_URL}/user/reset-password`


export function login(email, password) {
  return axios.post(LOGIN_URL_APPZ4 , {
    email,
    password,
  })
}



export function register(
  name,
  phone,
  email,
  password,
  country,
  vatNumber,
  language,
  trade,
) {
  return axios.post(REGISTER_URL, {
    name: name,
    phone:phone,
    email: email,
    password: password,
    country_id: country,
    currency_id: 1,
    vatNumber: vatNumber,
    languageId:language,
    retail:trade,
    permission:5
  })
}

export function registerWithAffiliate(
  name,
  phone,
  email,
  password,
  country,
  vatNumber,
  language,
  trade,
  affiliate
) {
  return axios.post(REGISTER_URL, {
    name: name,
    phone:phone,
    email: email,
    password: password,
    country_id: country,
    currency_id: 1,
    vatNumber: vatNumber,
    languageId:language,
    retail:trade,
    permission:5,
    affiliate:affiliate
  })
}

// Server should send e-mail with newPassword url
export function forgottPassword(email,url) {
  return axios.post(FORGOT_PASSWORD_URL, {
    email,
    url
  })
}


export function requestPassword(token,password,password_confirmation) {
  return axios.post(REQUEST_PASSWORD_URL, {
    token,
    password,
    password_confirmation
  })
}


// export function getUserByToken(token) {
//   return axios.post(GET_USER_BY_ACCESSTOKEN_URL, {
//     api_token: token,
//   })
// }
