import { useQueryResponseAffiliateGuestsData, useQueryResponseAffiliateGuestsLoading } from "./core/QueryResponseProviderAffiliateGuests"
import { MdOutlineClose } from "react-icons/md";

const ConnectedUsersModalContent = ({ onCancel }) => {

    const affiliateJoinedDatas = useQueryResponseAffiliateGuestsData();
    const loading = useQueryResponseAffiliateGuestsLoading();

    return (
        <div className="form_ver-red-wrapper joined-user-modal">
            <div className="box-wrapper">
                <div className="boxed--table-type cutted-corner confirmed-order-corner">
                    <div className="boxed-header">
                        <div className="card-title d-flex">

                            <h3 className="section-title--big mb-0">Joined Users</h3>
                        </div>
                        <button className={"full-screen-bt cancel-bt"} onClick={onCancel} type={'button'}><MdOutlineClose /></button>
                    </div>
                    <div className="table-wrapper mt-4">
                        {(affiliateJoinedDatas.length > 0) && <table>
                            <thead>
                                <tr className="confirmed-tableRow__item">
                                    <th>Name</th>
                                    <th>Status</th>
                                    <th className="text-end">Coins</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    affiliateJoinedDatas.map((item) => {
                                        return (
                                            <tr className="confirmed-tableRow__item">
                                                <td className="name-td">{item.name}</td>
                                                <td className={`${item.status != 1 ? "passive-status" : "active-status"}`}>{item.status != 1 ? "Passive" : "Active"}</td>
                                                <td className="text-end">{item.points} COIN</td>
                                            </tr>)
                                    })
                                }
                            </tbody>
                        </table>}
                        {(affiliateJoinedDatas.length == 0) && (<h3 className="empty-title">Your list is empty!</h3>)}
                    </div>
                </div>
            </div>
        </div>)

}

export default ConnectedUsersModalContent;