import * as React from "react"

const Cart = (props) => (
    <svg
    width={16}
    height={16}
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    {...props}
  >
    <title>{"Layer 1"}</title>
    <path
      stroke="null"
      fill="#000"
      d="M15.333 2.042H4.008L3.765.619C3.705.259 3.43 0 3.111 0H.333C.15 0 0 .171 0 .383v.765c0 .212.15.383.333.383h2.23l1.932 11.347A2.202 2.202 0 0 0 4 14.292c0 1.127.796 2.041 1.778 2.041s1.778-.914 1.778-2.041c0-.359-.082-.711-.239-1.021h4.033c-.157.31-.24.662-.239 1.02 0 1.128.796 2.042 1.778 2.042s1.778-.914 1.778-2.041c0-.579-.21-1.1-.547-1.472l.029-.152c.09-.477-.226-.928-.652-.928H5.66l-.26-1.532h8.677c.314 0 .585-.25.652-.603l1.256-6.635c.09-.477-.227-.928-.652-.928zM5.778 15.057c-.368 0-.667-.343-.667-.765 0-.423.3-.766.667-.766.367 0 .666.343.666.766 0 .422-.299.765-.666.765zm7.11 0c-.367 0-.666-.343-.666-.765 0-.423.3-.766.667-.766.367 0 .667.343.667.766 0 .422-.3.765-.667.765zm.652-6.38H5.138l-.87-5.104h10.238l-.966 5.104z"
    />
  </svg>
)

export default Cart