import { QueryProductResponseProvider } from "../products/QueryResponseProvider";
import { QueryBrandResponseProvider } from "../brands/QueryResponseProviderBrand";
import { QueryTypeResponseProvider } from "../product-types/QueryResponseProviderType";
import { QueryCategoryResponseProvider } from "../product-category/QueryResponseProviderCategory";
import { QueryCartResponseProvider } from "../cart/QueryResponseProviderCart";
import { Outlet } from "react-router-dom";

const PrivateContextsWrapper = () => {
  return (
    <QueryProductResponseProvider>
      <QueryBrandResponseProvider>
        <QueryTypeResponseProvider>
          <QueryCategoryResponseProvider>
            <QueryCartResponseProvider>            
                  <Outlet />
            </QueryCartResponseProvider>
          </QueryCategoryResponseProvider>
        </QueryTypeResponseProvider>
      </QueryBrandResponseProvider>
    </QueryProductResponseProvider>
  );
};

export { PrivateContextsWrapper };
