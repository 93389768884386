import React, { useState, useEffect, useMemo } from "react";
import clsx from "clsx";

const CustomSelect = ({
  defaultText,
  optionsList,
  formik,
  fieldName,
  searchable = false,
}) => {
  const [defaultSelectText, setDefaultSelectText] = useState();
  const [selectedIndex, setSelectedIndex] = useState(0);


  //if undifened defaultState: true
  const [defaultState, setDefaultState] = useState(
    formik?.values[fieldName] || formik?.values[fieldName] >= 0 ? false : true
  );

  const [showOptionsList, setShowOptionList] = useState(false);
  const [firstInit, setFirstInit] = useState(true);
  const [query, setQuery] = useState("");

  const filteredList = useMemo(() => {
    if (!query) return optionsList;
    return optionsList.filter(
      (item) =>
        item.name.toLowerCase().includes(query.toLowerCase()) ||
        item.id.toString().toLowerCase().includes(query.toLowerCase())
    );
  }, [optionsList, query]);



  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    setDefaultSelectText(defaultText);
    setFirstInit(false);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // This method handles the click that happens outside the
  // select text and list area
  const handleClickOutside = (e) => {
    if (
      !e.target.classList.contains("custom-select-option") &&
      !e.target.classList.contains("selected-text") &&
      !e.target.classList.contains("select-options") &&
      !e.target.classList.contains("query-input")
    ) {
      setShowOptionList(false);
    }
  };

  // This method handles the display of option list
  const handleListDisplay = () => {
    if (!showOptionsList) {
      setShowOptionList(true);
    }
  };

  // This method handles the setting of name in select text area
  // and list display on selection
  const handleOptionClick = (e) => {
    setDefaultSelectText(e.target.getAttribute("data-name"));
    formik.setFieldValue(fieldName, e.target.id);
    setShowOptionList(false);
    setDefaultState(false);
  };

  const handleKeyUp = (e) => {
    if (e.charCode === 13) {
      e.preventDefault();
      if (filteredList.length == 0) return;
      setDefaultSelectText(filteredList[0].name);
      formik.setFieldValue(fieldName, filteredList[0].id);
      setShowOptionList(false);
      setDefaultState(false);
    }
  };

  console.log(selectedIndex)
  console.log(showOptionsList)

  return (
    <>
      {firstInit && (
        <div className="custom-select-container">
          <div className="selected-text default-select">Progress..</div>
        </div>
      )}

      {!firstInit && (
        <div className="custom-select-container">
          <div
            className={clsx(
              [showOptionsList ? "selected-text active" : "selected-text"],
              { " default-select": defaultState }
            )}
            onClick={handleListDisplay}
          >
            {defaultSelectText}
          </div>
          {showOptionsList && (
            <>
              {searchable && (
                <input
                  placeholder="Search.."
                  className="query-input"
                  onChange={(e) => setQuery(e.target.value)}
                  onBlur={(e) => setQuery(e.target.value)}
                  onClick={handleListDisplay}
                  value={query}
                  disabled={!searchable}
                  onKeyPress={handleKeyUp}
                  type="text"
                ></input>
              )}
              {filteredList?.length > 0 && (
                <ul
                  className="select-options pt-0"
                  {...formik.getFieldProps(fieldName)}
                >
                  {filteredList.map((option, index) => {
                    return (
                      <li
                        className={`custom-select-option ${
                          index == selectedIndex ? "active-option" : ""
                        }`}
                        data-name={option.name}
                        key={option.id}
                        id={option.id}
                        onClick={handleOptionClick}
                      >
                        {option.name}
                      </li>
                    );
                  })}
                </ul>
              )}
            </>
          )}
        </div>
      )}
    </>
  );
};

export default CustomSelect;
