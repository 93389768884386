import React from "react";
import { useTransition, animated, config } from "react-spring";
import ConnectedUsersModalContent from "./ConnectedUsersModalContent";
import { QueryAffiliateGuestsResponseProvider } from "./core/QueryResponseProviderAffiliateGuests";
function ConnectedUsersModal({ isShow, onCancel }) {

  const props = useTransition(isShow, {
    from: { opacity: 0, transform: "translate(-50%, -400%)" },
    enter: { opacity: 1, transform: "translate(-50%, -50%)" },
    leave: { opacity: 0, transform: "translateY(-50%, -400%)" },
    config: config.stiff
  });


  const propsWrapper = useTransition(isShow, {
    from: { opacity: 0 },
    enter: { opacity: 0.78 },
    leave: { opacity: 0 }
  });

  return (
    <div>
      {propsWrapper((styles, item) =>
        item &&
        <animated.div
          style={styles}
          onClick={onCancel}
          className="modal-close-wrapper"
          id={"modal-close-wrapper"}
        />
      )}
      {props((styles, item) =>
        item &&
        <animated.div style={styles} className={`modal-container form-container ${isShow ? "modal-is-open" : "modal-is-close"}`}>
          <QueryAffiliateGuestsResponseProvider>
            <ConnectedUsersModalContent onCancel={onCancel} />
          </QueryAffiliateGuestsResponseProvider>
        </animated.div>
      )}
    </div>
  );
}

export default ConnectedUsersModal;