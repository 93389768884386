/* eslint-disable jsx-a11y/anchor-is-valid */
import { Outlet, Route, Routes } from "react-router-dom";
import ForgotPassword from './components/ForgotPassword'
import ForgotPasswordForm from './components/ForgotPasswordForm'
import LoginPage from "./components/LoginPage";
import Registration from "./components/Registration";
import RegistrationWithAffiliate from "./components/RegistratioWithAffiliate";

import { toAbsoluteUrl } from "../../../_clientPage/assets/helper/AssetHelper";

const AuthLayout = () => {
  //   useEffect(() => {
  //     document.body.classList.add('bg-white')
  //     return () => {
  //       document.body.classList.remove('bg-white')
  //     }
  //   }, [])

  return (
    <div
      className="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed"
      style={{
        backgroundImage: `url(${toAbsoluteUrl(
          "/media/illustrations/sketchy-1/14.png"
        )})`,
      }}
    >
      <div className="d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20 position-relative">
        <div className="w-lg-500px rounded shadow-sm p-10 p-lg-15 mx-auto">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

const AuthPage = () => (
  <Routes>
    <Route element={<AuthLayout />}>
      <Route index element={<LoginPage />} />
      <Route path="/registration" element={<Registration />} />
      <Route path='/forgotten-password' element={<ForgotPassword />} />
      <Route path='/forgotten-password/:token' element={<ForgotPasswordForm />} />
      <Route path="/registration/:id" element={<RegistrationWithAffiliate />} />
    </Route>
  </Routes>
);

export { AuthPage };
