import { FaBars } from 'react-icons/fa';
import CartIcon from '../components/cartIcon/cart'

const Navbar = ({handleToggleSidebar}) => {

    return (
        <div className='mobil-menu'>
            <div className="btn-toggle" onClick={handleToggleSidebar(true)}>
                <FaBars />
            </div>
            <div className="logo-container">
                <div className="logo"></div>
            </div>
            <CartIcon classes={"cart__icon cart__icon__mobile"} classesWrapper={"header-imgs-wrapper"}/>
        </div>
    )
}
export default Navbar;