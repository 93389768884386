import { AUTH_LOCAL_STORAGE_KEY } from './_constants'

const getAuth = () => {
  if (!localStorage) {
    return
  }

  const lsValue = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY)
  if (!lsValue) {
    return
  }

  try {
    const auth = JSON.parse(lsValue)
    if (auth) {
      // You can easily check auth_token expiration also
      // console.log(auth)
      return auth
    }
  } catch (error) {
    console.error('AUTH LOCAL STORAGE PARSE ERROR', error)
  }
}

const setAuth = (auth) => {

  if (!localStorage) {
    return
  }


  try {
    const lsValue = JSON.stringify(auth)

    localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, lsValue)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE SAVE ERROR', error)
  }
}

const removeAuth = () => {
  if (!localStorage) {
    return
  }

  try {
    localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE REMOVE ERROR', error)
  }
}

export function setupAxios(axios) {
  axios.defaults.headers.Accept = 'application/json'
  axios.interceptors.request.use(
    (config) => {
      const auth = getAuth()
      if (auth && auth.data.token) {
        config.headers.Authorization = `Bearer ${auth.data.token}`
      }

      return config
    },
    (err) => Promise.reject(err)
  )
}


//setup enviroment url
//currently one url available
//START SETUP ENV

//ENV CODES TABLE
//0: dev,
//1: test,
//2: prod

const baseURLDev = process.env.REACT_APP_APPZ4_BACKEND_URL;
const baseURLProd = '';

let backendHost = "";


const hostname = window && window.location && window.location.hostname;
const devCode = 0;
if (!hostname.includes("localhost" && !hostname.includes("solarhero"))) {
  backendHost = baseURLProd;
} else {
  backendHost = baseURLDev;
}

export const API_URL = backendHost;
export const ENV_CODE = devCode;
//END SETUP ENV

export { getAuth, setAuth, removeAuth }
