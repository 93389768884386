import * as React from "react"

const Warehouse = (props) => (
    <svg
    width={16}
    height={14}
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    {...props}
  >
    <title>{"Layer 1"}</title>
    <path
      stroke="null"
      fill="#1D1F26"
      d="M15.556 1.344c.245 0 .444-.2.444-.448V.448A.446.446 0 0 0 15.556 0H.444C.2 0 0 .2 0 .448v.448c0 .247.199.448.444.448h.444V12.99H.444c-.245 0-.444.2-.444.448v.447c0 .248.199.448.444.448h15.112c.245 0 .444-.2.444-.448v-.447a.446.446 0 0 0-.444-.448h-.445V1.344h.445zM13.778 12.99h-4.89v-2.24h1.779A2.677 2.677 0 0 0 8 8.063a2.677 2.677 0 0 0-2.667 2.687h1.778v2.24h-4.89V1.344h11.557V12.99zM7.467 4.48h1.066c.178 0 .356-.18.356-.36V3.047c0-.18-.178-.358-.356-.358H7.467c-.178 0-.356.179-.356.358V4.12c0 .179.178.358.356.358zm0 2.687h1.066c.178 0 .356-.18.356-.359V5.733c0-.179-.178-.358-.356-.358H7.467c-.178 0-.356.18-.356.358v1.075c0 .18.178.359.356.359zm3.555 0h1.067c.178 0 .355-.18.355-.359V5.733c0-.179-.177-.358-.355-.358h-1.067c-.178 0-.355.18-.355.358v1.075c0 .18.177.359.355.359zm0-2.688h1.067c.178 0 .355-.179.355-.358V3.046c0-.18-.177-.358-.355-.358h-1.067c-.178 0-.355.179-.355.358V4.12c0 .179.177.358.355.358zm-7.11 2.688h1.066c.178 0 .355-.18.355-.359V5.733c0-.179-.177-.358-.355-.358H3.91c-.178 0-.355.18-.355.358v1.075c0 .18.177.359.355.359zm0-2.688h1.066c.178 0 .355-.179.355-.358V3.046c0-.18-.177-.358-.355-.358H3.91c-.178 0-.355.179-.355.358V4.12c0 .179.177.358.355.358z"
    />
  </svg>
)

export default Warehouse;