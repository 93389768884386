

import React, { useState } from "react";
import * as Yup from "yup";
import clsx from "clsx";
import { useFormik } from "formik";
import { requestPassword } from "../core/_requests";
import { Link, useParams, useNavigate } from "react-router-dom";
import EyeButton from "../../../../_clientPage/layout/partials/components/eye-button/EyeButton";
const initialValues = {
  password: "",
  changepassword: "",
  token: "",
};

const forgotPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
  changepassword: Yup.string()
    .required('Password confirmation is required')
    .when('password', {
      is: (val) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf([Yup.ref('password')], "Password and Confirm Password didn't match"),
    })
});

const ForgotPasswordForm = () => {
  const [loading, setLoading] = useState(false);
  const [isSent, setIsSent] = useState(false);
  const [passwordType, setPasswordType] = useState("password");

  const { token } = useParams();

  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      try {
        const { data: resp } = await requestPassword(
          token ? token : "null",
          values.password,
          values.changepassword
        );
        setLoading(false);
        setIsSent(true);
        setStatus("The password has changed!");
        setTimeout(() => {
          navigate("/");
        }, 1000);
      } catch (error) {
        console.error(error);
        setStatus("The password details is incorrect");
        setSubmitting(false);
        setLoading(false);
      }
    },
    enableReinitialize: true,
  });
  const navigate = useNavigate();
  return (
    <>
      <div className="bg-image-container">
        <div className="bg-image"></div>
      </div>
      <div className="bg-image__cover-overlay"></div>
      <div className="page">
        <section className="section--auths-boxes">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="row cols-wrapper">
                  <div className="col-11 col-xl-6 overlay-col">
                    <div className="overlay-container">
                      <div>
                        <div className="logo-wrapper">
                          <div className="logo-container">
                            <div className="logo"></div>
                          </div>
                        </div>
                        <h3>WELCOME IN THE WORLD OF</h3>
                        <h1>SOLAR HEROES!</h1>
                      </div>
                    </div>
                  </div>
                  <div className="col-11 col-xl-6 form-col">
                    <div className="form-container">
                      <div className="form_ver-red-wrapper form__light">
                        <form
                          className="cutted-corner auths-form forgotten-password-corner"
                          noValidate
                          onSubmit={formik.handleSubmit}
                        >
                          {formik.status && !isSent && (
                            <div className="mb-lg-15 alert alert-danger">
                              <div className="alert-text font-weight-bold">
                                {formik.status}
                              </div>
                            </div>
                          )}

                          {formik.status && isSent && (
                            <div className="mb-lg-15 success alert-success">
                              <div className="success-text font-weight-bold">
                                {formik.status}
                              </div>
                            </div>
                          )}
                          <h3 className="section-title--big">
                            Forgotten password
                          </h3>
                          <div className="gradient-border auth-input">
                            {" "}
                            <input
                              type={passwordType}
                              autoComplete="off"
                              {...formik.getFieldProps("password")}
                              className={clsx(
                                "form-control form-control-lg form-control-solid",
                                {
                                  "is-invalid":
                                    formik.touched.password &&
                                    formik.errors.password,
                                },
                                {
                                  "is-valid":
                                    formik.touched.password &&
                                    !formik.errors.password,
                                }
                              )}
                              placeholder="PASSWORD"
                            />

                            <EyeButton passwordType={passwordType} setPasswordType={setPasswordType} password={formik.values.password}></EyeButton>

                          </div>
                          {formik.touched.password && formik.errors.password && (
                            <div className="fv-plugins-message-container">
                              <div className="fv-help-block">
                                <span role="alert">
                                  {formik.errors.password}
                                </span>
                              </div>
                            </div>
                          )}
                          <div className="gradient-border auth-input">
                            <input
                              placeholder="PASSWORD AGAIN"
                              type="password"
                              autoComplete="off"
                              {...formik.getFieldProps("changepassword")}
                              className={clsx(
                                "form-control form-control-lg form-control-solid",
                                {
                                  "is-invalid":
                                    formik.touched.changepassword &&
                                    formik.errors.changepassword,
                                },
                                {
                                  "is-valid":
                                    formik.touched.changepassword &&
                                    !formik.errors.changepassword,
                                }
                              )}
                            />
                          </div>
                          {formik.touched.changepassword &&
                            formik.errors.changepassword && (
                              <div className="fv-plugins-message-container">
                                <div className="fv-help-block">
                                  <span role="alert">
                                    {formik.errors.changepassword}
                                  </span>
                                </div>
                              </div>
                            )}
                          <div className="button-container auth-input">

                            <button
                              type="submit"
                              id="kt_password_reset_submit"
                              className="global-button"
                            >
                              {!loading && (
                                <span className="indicator-label">Submit</span>
                              )}
                              {loading && (
                                <span className="indicator-progress">
                                  Please wait...
                                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                </span>
                              )}
                            </button>
                          </div>
                          <div className="already-account-wrapper mt-2">
                            <Link to="/">Back to Sign in</Link>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      {/* <NavigationBar prevLink={"/"} nextLink={"/confirmed-password"} /> */}
    </>
  );
};
export default ForgotPasswordForm;
