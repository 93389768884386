import affiliate from '../../../../_clientPage/assets/images/affiliate_benefits.webp'

const AffiliateNewModal = ({ onCancel }) => {

    return (
        <div className="form_ver-red-wrapper">
            <div className="confirmed-bot__wrapper __modal-bot__account">
                <div className="bot-account"></div>
            </div>
            <form className="cutted-corner auths-form add-warehouse" action="#">
                <img className="w-100" src={affiliate}></img>
                <div className="button-wrapper mt-4">
                    <div className="button-container auth-input">
                        <button type="button" className="global-button grey-background" onClick={onCancel}>Close</button>
                    </div>
                </div>
            </form>

        </div>
    )
}

export default AffiliateNewModal;