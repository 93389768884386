import axios from 'axios'
import { isDefault } from '../../../../assets/helper/AssetHelper'
const APPZ4_BACKEND_URL = process.env.REACT_APP_APPZ4_BACKEND_URL
export const REGISTER_URL = `${APPZ4_BACKEND_URL}/address`
export const EDIT_ADDRESS_URL = `${APPZ4_BACKEND_URL}/address/`

export const USER_URL = `${APPZ4_BACKEND_URL}/user`

export function addressRegister(
  User
) {
  return axios.post(REGISTER_URL, {
    name: User.name,
    phone: User.phone,
    zip: User.zip,
    country_id: User.country_id,
    city: User.city,
    street: User.street,
    apartment: User.apartment,
    warehouse: User.warehouse,
    default: isDefault(User.default)
  })
}


export function updateCustomer(
  User,
) {
  return axios.patch(USER_URL + "/" + User.id, {
    name: User.name,
    phone: User.phone,
    country_id: User.country_id,
    email: User.email,
    active: User.active,
    currency_id: User.currency_id,
    language_id: User.language_id,
    permission: User.permission,
    price_category_id: User.price_category_id,
    retail: User.retail,
    vat_number: User.vat_number
  })
}

export function getUser(
  id,
) {
  return axios.get(USER_URL + "/" + id)
}