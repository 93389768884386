import { useLocation } from "react-router-dom"
// import { useQueryResponseCategoryData, useQueryResponseCategoryLoading } from "../../../providers/privateProviders/product-category/QueryResponseProviderCategory"

import { useQueryResponseCategoryData, useQueryResponseCategoryLoading } from "../../../../../app/providers/privateProviders/product-category/QueryResponseProviderCategory"

import { useQueryResponseBrandData, useQueryResponseBrandLoading } from '../../../../../app/providers/privateProviders/brands/QueryResponseProviderBrand'
import { useQueryResponseTypeData, useQueryResponseTypeLoading } from '../../../../../app/providers/privateProviders/product-types/QueryResponseProviderType'
import { TailLoading } from "../../components/loading/TailSpinner"
const MapRoutes = () => {
    const { search } = useLocation()


    const categoryId = new URLSearchParams(search).get("categoryId")
    const brandId = new URLSearchParams(search).get("brandId")
    const typeId = new URLSearchParams(search).get("typeId")

    const typeData = useQueryResponseTypeData();
    const typeDataLoading = useQueryResponseTypeLoading()
    const categoryData = useQueryResponseCategoryData();
    const categoryLoading = useQueryResponseCategoryLoading();
    const brandData = useQueryResponseBrandData();
    const brandLoading = useQueryResponseBrandLoading();

    return (
        <h3 className="map-route">
            {(!brandLoading && !typeDataLoading && !categoryLoading) &&
                <>
                    <span>PRODUCTS /</span> {(categoryId != 'null' && categoryId != undefined) && <span className='point-item'>{categoryData[categoryData.findIndex(item => item.id == categoryId)]?.name + " / "}</span>} {(brandId != 'null' && brandId != undefined) && <span className='point-item'>a{brandData[brandData.findIndex(item => item.id == brandId)]?.name + " / "}</span>}{(typeId != 'null' && typeId != undefined) && <span>{typeData[typeData.findIndex(item => item.id == typeId)]?.name}</span>}
                </>
            }
            {(brandLoading && typeDataLoading && categoryLoading) &&
                <>
                    <span><TailLoading/></span>
                </>
            }
        </h3>

    )
}

export default MapRoutes;