import { Suspense } from "react";
import { Outlet } from "react-router-dom";
import { LayoutSplashScreen } from "./_clientPage/layout/core/SplashScreen";
import { AuthInit } from "./app/modules/auth";
import React from "react";
import { ContextsWrapper } from "./app/providers/globalProviders/ContextsWrapper";
import { LayoutProvider } from "./_clientPage/layout/core/_LayoutProvider";

const App = () => {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <LayoutProvider>
      <AuthInit>
        <ContextsWrapper>
          <Outlet />
        </ContextsWrapper>
      </AuthInit>
      </LayoutProvider>
    </Suspense>
  );
};

export { App };
