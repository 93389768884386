/* eslint-disable react-hooks/exhaustive-deps */
import {FC, useContext, useState, useEffect, useMemo,createContext} from 'react'
import {useQuery} from 'react-query'
import {
  initialQueryResponse,
} from '../../../../../_clientPage/assets/helper/model'
import {getCountries,COUNTRY_URL} from './requests'



const QueryCountryResponseContext = createContext(initialQueryResponse)
const QueryCountryResponseProvider = ({children}) => {
 
  const [query, setQuery] = useState(COUNTRY_URL)


  const {
    isFetching,
    refetch,
    data: response,
  } = useQuery(
    `${query}`,
    () => {
       return getCountries()
     },
    {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
  )

  return (
    <QueryCountryResponseContext.Provider value={{isLoading: isFetching, refetch, response,query}}>
      {children}
    </QueryCountryResponseContext.Provider>
  )
}

const useQueryResponse = () => useContext(QueryCountryResponseContext)

const useQueryResponseCountryData = () => {
  const {response} =  useQueryResponse()
  if (!response) {
    return []
  }
  return response.data.countries || []
}


const useQueryResponseLoadingCountry = () => {
  const {isLoading} = useQueryResponse()
  return isLoading
}

export {
  QueryCountryResponseProvider,
  useQueryResponse,
  useQueryResponseCountryData,
  useQueryResponseLoadingCountry,
}
