
import { Oval } from  'react-loader-spinner'

const TailLoading = () => {

    return (
        <Oval
            height="40"
            width="40"
            color="#E4072F"
            wrapperStyle={{justifyContent:'center'}}
            wrapperClass=""
            visible={true}
            ariaLabel='oval-loading'
            secondaryColor="#E4072F"
            strokeWidth={2}
            strokeWidthSecondary={2}
        />
    )
}

export {TailLoading}