/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useState, createContext } from "react";
import { useQuery } from "react-query";
import { initialQueryResponse } from "../../../../_clientPage/assets/helper/model";
import { getBrands, GET_BRANDS_URL } from "./_requests";

const QueryBrandResponseContext = createContext(initialQueryResponse);
const QueryBrandResponseProvider = ({ children }) => {
  const [query, setQuery] = useState("/brands");
  const { isFetching: isLoading, data: response, refetch } = useQuery(
    `${GET_BRANDS_URL}`,
    () => {
      return getBrands();
    },
    {
      cacheTime: 0,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );


  return (

    <QueryBrandResponseContext.Provider
      value={{ isLoading: isLoading, refetch, response, query }}
    >
      {children}
    </QueryBrandResponseContext.Provider>
  );
};

const useQueryResponse = () => useContext(QueryBrandResponseContext);

const useQueryResponseBrandData = () => {
  const { response } = useQueryResponse();
  if (!response) {
    return [];
  }

  return response || [];
};

const useQueryResponseBrandLoading = () => {
  const { isLoading } = useQueryResponse();
  return isLoading;
};


export {
  QueryBrandResponseProvider,
  useQueryResponse,
  useQueryResponseBrandData,
  useQueryResponseBrandLoading,
};
