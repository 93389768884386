import { orderStatus } from "./consts";

function isNotEmpty(obj) {
  return obj !== undefined && obj !== null && obj !== ''
}

function calculateCurrentQuantityFromCart(obj) {
  let total = 0;
  Object.values(Array.from(obj))[0][1].map((item) => {
    total += item.quantity;
  })

  return total;
}

function calculateProductTotalPrice(obj, id) {
  let total = 0;


  obj.map((item) => {
    if (item.product_id == id) {
      total += item.subtotal;
    }
  })

  return total;
}

function getOrderStatusName(order_status) {

  const statusName = orderStatus?.filter((item) => item.id == order_status)

  if (statusName?.length > 0) {
    return statusName[0].name
  }

  return "";
}

function getDeliveryStatusName(order_status) {

  if (!order_status) {
    return ""
  }

  if (order_status < 7) {
    return "In Progress"
  }

  else if (order_status == 7) {
    return "Delivered"
  }

  else if (order_status == 99) {
    return "Canceled"
  }

  return "";
}

function getPaymentStatusName(order_status) {

  if (!order_status) {
    return ""
  }

  if (order_status < 5) {
    return "Pending"
  }

  else if (order_status >= 5 && order_status <= 7) {
    return "Paid"
  }

  else if (order_status == 99) {
    return "Canceled"
  }

  return "";
}

function makeid(length) {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
}

export { makeid,isNotEmpty, calculateCurrentQuantityFromCart, calculateProductTotalPrice, getOrderStatusName,getDeliveryStatusName,getPaymentStatusName }