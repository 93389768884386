import { Link } from 'react-router-dom';
import { useState } from "react";
import { useAuth } from "../core/Auth";
import { useFormik } from "formik";
import { login } from "../core/_requests";
import clsx from "clsx";
import { errorHandle } from "../../../../_clientPage/assets/helper/error-handler/errorHandler";
import * as Yup from "yup";
import EyeButton from '../../../../_clientPage/layout/partials/components/eye-button/EyeButton';

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email("Wrong email format")
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Email is required"),
  password: Yup.string()
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Password is required"),
});

const initialValues = {
  email: "admin4@demo.com",
  password: "12345678A",
};

const LoginPage = () => {
  const { saveAuth, setCurrentUser } = useAuth();
  const [success, setSuccess] = useState(false);
  const [passwordType, setPasswordType] = useState("password");


  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setSubmitting(true);
      try {
        const { data: auth } = await login(values.email, values.password);

        saveAuth(auth);

        setCurrentUser(auth);

        setSuccess(true)
        setTimeout(() => {
          setSuccess(false)
        }, 1000)

      } catch (error) {
        saveAuth(undefined);
        setStatus('The details is incorrect')
        const errorText = errorHandle(error)
        setStatus(errorText)
        setTimeout(() => {
          setStatus("")
        }, 2500)
      } finally {
        setSubmitting(false)
      }
    },
  });

  return (
    <>
      <div className="bg-image-container">
        <div className="bg-image"></div>
      </div>
      <div className="bg-image__cover-overlay"></div>
      <div className="page">
        <section className="section--auths-boxes">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="row cols-wrapper">
                  <div className="col-11 col-xl-6 overlay-col">
                    <div className="overlay-container">
                      <div>
                        <div className="logo-wrapper">
                          <div className="logo-container">
                            <div className="logo"></div>
                          </div>
                        </div>
                        <h3>WELCOME IN THE WORLD OF</h3>
                        <h1>SOLAR HEROES!</h1>
                      </div>
                    </div>
                  </div>
                  <div className="col-11 col-xl-6 form-col">
                    <div className="form-container">
                      <div className="form_ver-red-wrapper form__light">
                        <form
                          className="cutted-corner auths-form signin-corner"
                          onSubmit={formik.handleSubmit} noValidate
                        >
                          <h3 className="section-title--big">Sign in</h3>
                          <div className="gradient-border auth-input normal-input">
                            <input
                              placeholder="Email"
                              {...formik.getFieldProps("email")}
                              className={clsx(
                                "form-control form-control-lg form-control-solid",
                                {
                                  "is-invalid":
                                    formik.touched.email && formik.errors.email,
                                },
                                {
                                  "is-valid":
                                    formik.touched.email &&
                                    !formik.errors.email,
                                }
                              )}
                              type="email"
                              name="email"
                              autoComplete="off"
                            />

                          </div>
                          {formik.touched.email && formik.errors.email && (
                            <div className="fv-plugins-message-container">
                              <span role="alert">{formik.errors.email}</span>
                            </div>
                          )}
                          <div className="gradient-border auth-input normal-input">
                            <input
                              type={passwordType}
                              autoComplete="off"
                              {...formik.getFieldProps("password")}
                              className={clsx(
                                "form-control form-control-lg form-control-solid",
                                {
                                  "is-invalid":
                                    formik.touched.password &&
                                    formik.errors.password,
                                },
                                {
                                  "is-valid":
                                    formik.touched.password &&
                                    !formik.errors.password,
                                }
                              )}
                            />
                            <EyeButton passwordType={passwordType} setPasswordType={setPasswordType} password={formik.values.password}></EyeButton>
                          </div>
                          {formik.touched.password && formik.errors.password && (
                            <div className="fv-plugins-message-container">
                              <div className="fv-help-block">
                                <span role="alert">
                                  {formik.errors.password}
                                </span>
                              </div>
                            </div>
                          )}
                          <div className="button-container auth-input">

                            {formik.status && (
                              <div className='alert alert-danger custom-danger mt-6'>
                                <div className='alert-text font-weight-bold'>Invalid username or password!</div>
                              </div>
                            )}


                            {success && (
                              <div className='mb-10 bg-light-success p-4 rounded mt-6'>
                                <div className='text-success'>Successful login!</div>
                              </div>
                            )}
                            <button
                              type="submit"
                              id="kt_sign_in_submit"
                              className="global-button"
                              disabled={formik.isSubmitting}
                            >
                              {!formik.isSubmitting && (
                                <span className="indicator-label">
                                  Log in
                                </span>
                              )}
                              {formik.isSubmitting && (
                                <span
                                  className="indicator-progress"
                                  style={{ display: "block" }}
                                >
                                  Please wait...
                                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                </span>
                              )}
                            </button>
                          </div>
                          <div className="already-account-wrapper mt-1">
                            Don’t have an account yet?
                            <Link to="/auth/registration"> Register</Link>
                          </div>
                          <div className="already-account-wrapper">
                            <Link to="/auth/forgotten-password">
                              Did you forget you password?
                            </Link>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>


    </>
  );
};
export default LoginPage;
