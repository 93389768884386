import {useState, createContext, useContext} from 'react'

export const initialNotification = {  
    setNotification: () => {},
  }

const NotificationContext = createContext(initialNotification)

const NotificationProvider = ({children}) => {
  const [notification, setNotification] = useState()

  return (
    <NotificationContext.Provider
      value={{  
        notification,
        setNotification,
      }}
    >
      {children}
    </NotificationContext.Provider>
  )
}

const useNotification = () => useContext(NotificationContext)

export {NotificationProvider, useNotification}