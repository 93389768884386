import clsx from 'clsx'
import React from 'react'
import { createPortal } from 'react-dom'



const Notification = ({
  showNotification,
  message,
  status,
}) => {


  return showNotification && !!message
    ? createPortal(
        <div
          className={clsx(
            'noti-wrapper',
            {
              'bg-light-success text-success': status === 'success',
              'bg-danger alert-text fw-bolder': status === 'error',
            }
          )}
        >
          <p>{message}</p>
        </div>,
        document.body
      )
    : null
}

export default React.memo(Notification)