/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useState, createContext } from "react";
import { useQuery } from "react-query";
import { initialQueryResponse } from "../../../../_clientPage/assets/helper/model";
import { getCart, GET_CART_URL } from "./_requests";
import { useAuth } from "../../../modules/auth";

const QueryCartResponseContext = createContext(initialQueryResponse);
const QueryCartResponseProvider = ({ children }) => {
  const [query, setQuery] = useState("/cart");
  const [cartData, setCartData] = useState(undefined);
  const { currentUser } = useAuth();

  const { isFetching: isLoading, refetch,data: response } = useQuery(
    `${query}`,
    () => {
      return getCart(currentUser.data.user.id);
    },
    {
      onSuccess: (data) => {
        setCartData(data);
      },
      cacheTime: 0,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );


  return (
  
        <QueryCartResponseContext.Provider
          value={{ isLoading: isLoading, refetch, response, query }}
        >
          {children}
        </QueryCartResponseContext.Provider>
  );
};

const useQueryResponse = () => useContext(QueryCartResponseContext);

const useQueryResponseCartData = () => {
  const { response } = useQueryResponse();
  if (!response) {
    return [];
  }

  return response || [];
};

const useQueryRefetchCartData = () => {
  const { refetch } = useQueryResponse();
  return refetch;
};

const useQueryResponseCartLoading = () => {
  const { isLoading } = useQueryResponse();
  return isLoading;
};
export {
  QueryCartResponseProvider,
  useQueryResponse,
  useQueryResponseCartData,
  useQueryResponseCartLoading,
  useQueryRefetchCartData
};
